<template>
	<div class="refund" :class="{ 'refund--upgrade': isUpgrade }">
		<div class="refund__inner">
			<div class="refund__shield" v-if="!isUpgrade">
				<img src="/images/refund-shield.png" alt="refund image" />
			</div>

			<div class="refund__content-container">
				<p v-if="isAddon">{{ $t('checkout.insurance.addon') }} {{ refundPrice | formatPrice }}</p>

				<p v-else-if="showTitle">{{ $t('checkout.insurance.title') }} {{ refundPrice | formatPrice }}</p>

				<p v-else-if="isUpgrade">{{ $t('checkout.insurance.upgrade') }} {{ refundPrice | formatPrice }}</p>

				<p v-else class="refund__text">
					{{ $t('checkout.insurance.default') }} {{ refundPrice | formatPrice }}
				</p>

				<div class="refund__content">
					<div class="refund__buttons">
						<RadioButton
							class="refund__radio"
							:data="{
								selectedValue: hasRefund,
								label: $t('label.yes'),
								value: true
							}"
							:isDisabled="isShopUpdatePending"
							@input="onSelect"
						/>

						<RadioButton
							class="refund__radio"
							:data="{
								selectedValue: hasRefund,
								label: $t('label.no'),
								value: false
							}"
							:isDisabled="isShopUpdatePending"
							@input="onSelect"
						/>
					</div>

					<transition type="in-out" name="fade">
						<div class="refund__info" v-if="!showMoreInfo">
							<span @click="toggleMoreInfo">{{ $t('label.more_info') }}</span>
						</div>
					</transition>
				</div>
			</div>
		</div>

		<transition type="in-out" name="fade">
			<div v-if="showMoreInfo">
				<p class="refund__info-xcover">{{ $t('checkout.insurance.xcover.body') }}</p>

				<div class="refund__info-link" v-html="$t('checkout.insurance.xcover.uri')"></div>

				<div class="refund__info-logo-container" v-if="isInsuranceLogo">
					<img class="refund__info-logo" :src="isInsuranceLogo.fields.file.url" alt="" />
				</div>
			</div>
		</transition>

		<p class="refund__warning" v-if="bookingProtectCopy" v-html="bookingProtectCopy.description"></p>
	</div>
</template>

<script>
import LargeRadio from '@/components/ui/LargeRadio';
import RadioButton from '@/components/ui/RadioButtonAsync';

export default {
	name: 'RefundProtection',

	components: {
		LargeRadio,
		RadioButton
	},

	props: {
		isUpgrade: {
			type: Boolean,
			default: false
		},
		isAddon: {
			type: Boolean,
			default: false
		},
		showTitle: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			selectedValue: '',
			showMoreInfo: false
		};
	},

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		getShopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		insurance() {
			return this.$store.getters['shopapi/getProducts'].insurance[0];
		},

		refundPrice() {
			// return 100;
			let price = this.shopConfig.orderServiceFee || 0; // Doesn't exist in preregistration mode
			Object.keys(this.productsInBasket).forEach(key => {
				if (
					['upgrades', 'travel', 'ticket', 'transportation', 'extras', 'addon', 'comparetransport'].includes(
						key
					)
				) {
					this.productsInBasket[key].forEach(item => {
						if (key === 'addon') {
							const product = item[Object.keys(item)[0]];
							const totalPrice = (
								product.type.options.find(option => option.amount == product.quantity).priceInCents /
								100
							).toFixed(2);
							price += Number(totalPrice);
						}
						if (item.type && item.type.priceExcludingServiceCosts) {
							const totalPrice = item.numproducts * item.type.price;
							price += totalPrice;
						}

						if (item.type && item.type.slug) {
							price += item.numproducts * item.type.price;
						}
						if (item.totalPrice) {
							price += item.totalPrice;
						}
					});
				}
			});
			this.$store.commit('shopapi/setInsurencePrice', (price / 100) * this.insurance.percentage);
			return (price / 100) * this.insurance.percentage;
		},

		isRefundChoiceMade() {
			return this.$store.getters['shopapi/getIsRefundChoiceMade'];
		},

		hasRefund() {
			if (this.isRefundChoiceMade) {
				return !!this.productsInBasket.insurance && !!this.productsInBasket.insurance.length;
			} else {
				return ''; // Not true or false, so by default no radio button is selected
			}
		},

		isShopUpdatePending() {
			return this.$store.getters['shopapi/getIsShopUpdatePending'];
		},

		hasUpgradeModule() {
			return this.$store.getters['shopconfig/getHasUpgradeModule'];
		},

		bookingProtectCopy() {
			const isBookingProtectAvailable =
				this.shopConfig.upgradableOrder &&
				this.shopConfig.upgradableOrder.bookingProtect &&
				this.shopConfig.upgradableOrder.bookingProtect.currentOrder;

			return isBookingProtectAvailable && this.shopConfig.upgradableOrder.bookingProtect;
		},

		isInsuranceLogo() {
			return this.getShopConfig.shopTheme ? this.getShopConfig.shopTheme.fields.insuranceLogo : false;
		}
	},

	methods: {
		onSelect(value) {
			this.$store.commit('shopapi/setIsRefundChoiceMade', true);
			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,
				params: {
					productId: this.insurance.id,
					quantity: value ? 1 : 0
				}
			};
			this.$store.dispatch(`shopapi/putRequest`, payload);
		},
		toggleMoreInfo() {
			this.showMoreInfo = !this.showMoreInfo;
		}
	}
};
</script>

<style lang="scss">
$component: 'refund';

.#{$component} {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: $color-white;

	&:not(&--upgrade) {
		padding: $global-spacing;

		// background: var(--content-background);
		background-color: rgba(black, 0.5);

		.#{$component} {
			&__info {
				flex: auto;
				display: flex;
				justify-content: flex-end;
			}
		}
	}

	&--upgrade {
		margin-bottom: $global-spacing;

		.#{$component} {
			&__content {
				padding-top: 0;
			}

			&__buttons {
				margin-right: 10px;
			}

			&__text {
				font-family: var(--font-bold);
				line-height: 1.4;
			}
		}
	}

	&__inner {
		display: flex;
	}

	&__shield {
		width: 50px;

		margin-right: $global-spacing;
	}

	&__content-container {
		flex: auto;
	}

	&__content {
		display: flex;

		padding-top: 10px;
	}

	&__buttons {
		display: flex;
	}

	&__radio {
		margin-right: 15px;
		margin-bottom: 0;
	}

	&__info {
		font-size: 14px;
		line-height: 21px;
		text-decoration: underline;
		cursor: pointer;
		transition-duration: 0.25s;
		&.fade-enter-active,
		&.fade-leave-active {
			transition: opacity 0.25s;
		}
		&.fade-enter,
		&.fade-leave-to {
			opacity: 0;
		}
	}

	&__info-xcover {
		margin: 1em 0 1em 70px;
		font-size: 11px;
		line-height: 1.4;
		transition-duration: 0.5s;
		&.fade-enter-active,
		&.fade-leave-active {
			transition: opacity 0.25s;
		}
		&.fade-enter,
		&.fade-leave-to {
			opacity: 0;
		}
	}

	&__info-link {
		margin: 1.25em 0 0 70px;
		font-size: 14px;
		line-height: 1.4;
	}

	&__info-logo-container {
		text-align: right;
	}
	&__info-logo {
		max-width: 6rem;
		max-height: 3rem;
	}

	&__warning {
		margin-top: 10px;

		color: $color-red;
		font-size: 14px;
	}

	@include md-lg {
		height: 100%;

		&--upgrade {
			.#{$component} {
				&__content-container {
					display: flex;
					align-items: center;
				}

				&__text {
					margin-right: 25px;
				}
			}
		}
	}

	@include sm {
		&:not(&--upgrade) {
			padding: 15px;
			margin-bottom: $global-spacing;
		}

		&--upgrade {
			.#{$component} {
				&__text {
					margin-bottom: 10px;
				}
			}
		}

		&__shield {
			width: 40px;
			margin-right: $global-spacing--mobile;
		}

		&__info-xcover {
			margin-left: 50px;
		}

		&__radio {
			label {
				font-size: 12px;

				&:before {
					margin-right: 7px;
				}
			}
		}
	}
}
</style>
