<template>
	<div class="nav-bar" v-if="routes && !this.isCheckoutStatusRoute">
		<div class="nav-bar__nav-buttons">
			<Button
				v-show="prevRoute"
				@click.native="stepBack"
				isNavBarButton
				class="button--small button--alternative nav-bar__button"
			>
				{{ previousClose }}
			</Button>

			<div class="nav-bar__spacer"></div>

			<template v-if="nextRoute">
				<Button
					v-if="!showLoader"
					class="button--small button--alternative nav-bar__button"
					:isDisabled="isNextDisabled"
					isNavBarButton
					@click.native="nextStep"
				>
					{{ $t('label.navigation.next') }}
				</Button>

				<Spinner class="button--small nav-bar__button nav-bar__button--spinner" v-else />
			</template>

			<Button
				v-else
				class="button--small button--alternative nav-bar__button"
				isNavBarButton
				@click.native="onClickCheckout"
				:isDisabled="isShopUpdatePending"
			>
				{{ goToCart }}
			</Button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NavBar',

	data() {
		return {
			showLoader: false,
			fromTravelDetailPath: null
		};
	},

	computed: {
		isCheckoutStatusRoute() {
			return this.$route.name === `slug-checkout`;
		},

		getProductsArray() {
			return this.$store.getters['shopapi/getProducts'];
		},

		cartHasPolicy() {
			return this.$store.getters['shopconfig/getPolicyId'] ? true : false;
		},

		previousClose() {
			return this.$route.name === 'slug-travel-detail' ||
				this.$route.name === 'slug-traveladdon-detail' ||
				this.$route.name === 'slug-transportation-eu' ||
				this.$route.name === 'slug-transportation-nl'
				? this.$t('label.navigation.overview')
				: this.$t('label.navigation.previous');
		},

		goToCart() {
			return this.shopConfig.preregistration
				? this.$t('label.navigation.wishlist')
				: this.$t('label.navigation.go_to_checkout');
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		routes() {
			let routes = this.$store.getters.getShopRoutingFlow;

			if (this.cartHasPolicy && this.getProductsArray) {
				routes = routes.filter(
					item => this.getProductsArray[item.type] && this.getProductsArray[item.type].length
				);
			}

			if (Object.keys(this.previousOrderTravelItems).length != 0) {
				if (routes) {
					routes = routes.filter(item => item.name != 'slug-travel');
				}
			} else {
				if (routes) {
					routes = routes.filter(item => item.name != 'slug-traveladdon');
				}
			}
			return routes;
		},

		currRouteIndex() {
			if (this.routes) {
				// console.log(this.$route.name);
				if (this.$route.name === 'slug-travel-detail') {
					return this.routes.findIndex(item => item.name === 'slug-travel');
				}
				if (this.$route.name === 'slug-traveladdon-detail') {
					return this.routes.findIndex(item => item.name === 'slug-traveladdon');
				}
				if (
					this.$route.name === 'slug-comparetransport-detail' ||
					this.$route.name === 'slug-comparetransport-eu' ||
					this.$route.name === 'slug-comparetransport-nl'
				) {
					return this.routes.findIndex(item => item.name === 'slug-comparetransport');
				} else if (
					this.$route.name === 'slug-transportation-eu' ||
					this.$route.name === 'slug-transportation-nl'
				) {
					return this.routes.findIndex(item => item.name === 'slug-transportation');
				} else {
					return this.routes.findIndex(item => item.path === this.$route.path);
				}
			}
		},

		prevRoute() {
			if (this.$route.name === 'slug-travel-detail') {
				return this.routes.find(item => item.name === 'slug-travel');
			} else if (this.$route.name === 'slug-traveladdon-detail') {
				return this.routes.find(item => item.name === 'slug-traveladdon');
			} else if (
				this.$route.name === 'slug-comparetransport-detail' ||
				this.$route.name === 'slug-comparetransport-eu' ||
				this.$route.name === 'slug-comparetransport-nl'
			) {
				return this.routes.find(item => item.name === 'slug-comparetransport');
			} else if (this.$route.name === 'slug-transportation-eu' || this.$route.name === 'slug-transportation-nl') {
				return this.routes.find(item => item.name === 'slug-transportation');
			} else {
				return this.currRouteIndex > 0 ? this.routes[this.currRouteIndex - 1] : null;
			}
		},

		nextRoute() {
			return this.currRouteIndex < this.routes.length - 1 ? this.routes[this.currRouteIndex + 1] : null;
		},

		getCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		hasTravelProduct() {
			return this.getCart && this.getCart.products.hasOwnProperty('hotel');
		},

		/* upgradesInCart() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'].upgrades;
		}, */

		isUpgradeChoiceMade() {
			return this.$store.getters.getIsUpgradeChoiceMade;
		},

		isNextDisabled() {
			return (this.$route.name === 'slug-upgrade' && !this.isUpgradeChoiceMade) || this.isShopUpdatePending;
		},

		isShopUpdatePending() {
			return this.$store.getters['shopapi/getIsShopUpdatePending'];
		},

		isAddonShop() {
			return this.shopConfig.shopInit && this.shopConfig.shopInit.isAddOnShop;
			// return this.shopConfig.shopInit && true
		},

		previousOrderTravelItems() {
			const prevProducts = this.$store.getters['shopapi/getPurchasedProducts'];
			const prevOrders = !!prevProducts && prevProducts.data.data['original_orders'];
			const hasPrevTravelOrder = {};
			!!prevOrders &&
				prevOrders.forEach(order => {
					const orderReference = order.reference;
					order.purchases.forEach(purchase => {
						if (purchase.title.search('Travel:') != -1) {
							hasPrevTravelOrder[`${orderReference}`] = purchase;
						}
					});
				});
			return hasPrevTravelOrder;
		}
	},

	watch: {
		currRouteIndex(val) {
			setTimeout(() => {
				this.showLoader = false;
			}, 1000);
		},
		$route(to, from) {
			// console.log(from.name, to.name);
			if (from.name === 'slug-travel-detail' && to.name != 'slug-travel') {
				this.fromTravelDetailPath = from.fullPath;
			} else {
				this.fromTravelDetailPath = null;
			}
		}
	},

	methods: {
		setTravelSlugPath(val) {
			//called from default.vue
			this.fromTravelDetailPath = val;
		},

		onClickCheckout() {
			this.$store.commit('setShowCheckout', true);
		},

		nextStep() {
			if (
				(this.$route.name === 'slug-travel' && this.fromTravelDetailPath) ||
				(this.$route.name === 'slug-traveladdon' && this.fromTravelDetailPath)
			) {
				this.$router.push({ name: this.fromTravelDetailPath, query: this.$route.query });
				this.fromTravelDetailPath = null;
				return;
			}

			if (this.fromTravelDetailPath) {
				this.fromTravelDetailPath = null;
			}

			if (
				this.$route.name === 'slug-travel-detail' ||
				this.$route.name === 'slug-traveladdon-detail' ||
				this.$route.name === 'slug-transportation-nl' ||
				this.$route.name === 'slug-transportation-eu'
			) {
				setTimeout(() => {
					this.showLoader = false;
				}, 1000);
			}

			this.showLoader = true;
			setTimeout(() => {
				this.$router.push({ path: this.nextRoute.path, query: this.$route.query });
			}, 100);
		},

		stepBack() {
			if (
				this.$route.name === 'slug-travel-detail' ||
				this.$route.name === 'slug-traveladdon-detail' ||
				this.$route.name === 'slug-transportation-nl' ||
				this.$route.name === 'slug-transportation-eu' ||
				this.$route.name === 'slug-comparetransport-detail' ||
				this.$route.name === 'slug-comparetransport-nl' ||
				this.$route.name === 'slug-comparetransport-eu'
			) {
				setTimeout(() => {
					this.showLoader = false;
				}, 1000);
			}

			if (this.fromTravelDetailPath) {
				this.$router.push({ path: this.fromTravelDetailPath, query: this.$route.query });
				this.fromTravelDetailPath = null;
				return;
			}

			this.showLoader = true;
			this.$router.push({ path: this.prevRoute.path, query: this.$route.query });
		}
	}
};
</script>

<style lang="scss">
$component: 'nav-bar';

.#{$component} {
	position: fixed;

	display: flex;
	// justify-content: flex-end;
	align-items: center;

	padding: 0 $global-spacing;

	width: 100%;
	height: $nav-height;
	bottom: 0;

	color: var(--navbar-text-color);

	background: rgba(var(--navbar-bg-color), 0.9);

	z-index: 555;

	&__button {
		max-width: 200px;

		cursor: pointer;

		&--spinner {
			margin-top: -(calc($nav-height / 2)) - 10px;
		}
	}

	&__nav-buttons {
		display: flex;
		justify-content: space-between;

		width: 100%;
	}

	&__checkout {
		display: flex;
		justify-content: space-between;

		width: 100%;
	}

	@include sm {
		height: $nav-bottom-height-mob;

		// padding: 0 $global-spacing--mobile;

		&__button {
			max-width: 150px;

			// height: 30px !important;
			// line-height: 0px !important;
		}
	}
}
</style>
