<template>
	<div class="basket-tickets">
		<BasketItem
			v-for="(ticket, index) in basketItemsData"
			:data="ticket"
			:key="`ticket${index}`"
			@input="onInputChange"
		/>
	</div>
</template>

<script>
import BasketItem from './BasketItem';

export default {
	name: 'BasketTickets',

	components: { BasketItem },

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		ticketsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'].ticket || [];
		},

		basketItemsData() {
			return this.ticketsInBasket
				.filter(ticket => {
					if (ticket.type.categories && ticket.type.categories.length) {
						return (
							ticket.type.categories[0].en !== 'TRAVEL TICKETS' &&
							ticket.type.categories[0].nl !== 'TRAVEL TICKETS'
						);
					}
					return true;
				})
				.map(ticket => {
					return {
						id: ticket.type.id,
						title: ticket.type.name || '',
						description: ticket.type.meta.description || '',
						price: this.calculateTicketPrice({ ticket }),
						touristTax: ticket.type.meta.touristTaxAmount,
						mentionEntertainmentFee: ticket.type.meta.mentionEntertainmentFee,
						entertainmentFee: ticket.type.meta.entertainmentFee,
						numberOfProducts: ticket.numproducts,
						stock: ticket.type.stockAvailable,
						maxAmount: Math.min(ticket.type.maxQuantity, this.shopConfig.maxTicketAmount),
						image: ticket.type.meta.image,
						icon: 'IconBuilderTicket',
						type: 'ticket',
						stepSize: ticket.type.stepSize,
						data: ticket
					};
				});
		}
	},

	methods: {
		async onInputChange({ productId, amount }) {
			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,
				params: {
					productId: productId,
					quantity: amount
				}
			};
			const addTicketsResult = await this.$store.dispatch(`shopapi/putRequest`, payload);

			if (!addTicketsResult || !addTicketsResult.status || addTicketsResult.status !== 200) {
				if (addTicketsResult.data && addTicketsResult.data.error && addTicketsResult.data.error.message) {
					this.$store.commit('setModalMessage', addTicketsResult.data.error.message); // message that gets shown by 8000 error modal
					this.$fireErrorEvent(8000);
				} else {
					console.log('Unknown error response:', addTicketsResult);
				}
			}
		},
		calculateTicketPrice({ ticket }) {
			if (
				typeof this.shopConfig.showDetailedServiceFees === 'undefined' ||
				this.shopConfig.showDetailedServiceFees
			) {
				return (
					ticket.type.priceExcludingServiceCosts -
					ticket.type.meta.touristTaxAmount -
					(ticket.type.meta.mentionEntertainmentFee ? ticket.type.meta.entertainmentFeeAmount : 0)
				);
			} else {
				return (
					ticket.type.price -
					ticket.type.meta.touristTaxAmount -
					(ticket.type.meta.mentionEntertainmentFee ? ticket.type.meta.entertainmentFeeAmount : 0)
				);
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'basket-tickets';

.#{$component} {
}
</style>
