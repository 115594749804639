import countryCodes from './countryCodes.json';
export default {
	data: [
		{
			type: 'Personal information',
			name: 'personal_information',
			fields: [
				{
					type: 'SelectField',
					label: 'Gender *',
					name: 'gender',
					model: 'gender',
					slot: [
						{
							type: 'Male',
							value: 'male'
						},
						{
							type: 'Female',
							value: 'female'
						},
						{
							type: 'Other',
							value: 'other'
						}
					]
				},
				{
					type: 'InputField',
					slot: 'First name *',
					name: 'firstname',
					model: 'firstName'
				},
				{
					type: 'InputField',
					slot: 'Surname *',
					name: 'lastname',
					model: 'lastName'
				},
				{
					type: 'SelectFieldCode',
					label: 'Country code *',
					name: 'countrycode',
					model: 'countryCode',
					slot: countryCodes
				},
				{
					type: 'InputField',
					slot: 'Phone number *',
					name: 'phonenumber',
					model: 'phoneNumber'
				},
				{
					type: 'EmailField',
					slot: 'Email *',
					name: 'email',
					model: 'email'
				},
				{
					type: 'ConfirmEmailField',
					slot: 'Repeat email to confirm *',
					name: 'emailconfirm',
					model: 'emailconfirm'
				},
				{
					type: 'DateField',
					label: 'Date of birth *',
					slot: 'Birthday',
					name: 'birthDay',
					model: 'dateOfBirth'
				}
			]
		},
		{
			type: 'Address Information',
			name: 'address_information',
			fields: [
				{
					type: 'InputField',
					slot: 'Street Name *',
					name: 'streetName',
					model: 'address'
				},
				{
					type: 'InputField',
					slot: 'Street Number *',
					name: 'streetNumber',
					model: 'streetNumber'
				},
				{
					type: 'InputField',
					slot: 'Number Suffix',
					name: 'extension',
					model: 'streetNumberExtension'
				},
				{
					type: 'InputField',
					slot: 'Postal Code *',
					name: 'postalCode',
					model: 'postalCode'
				},
				{
					type: 'InputField',
					slot: 'City *',
					name: 'city',
					model: 'city'
				},
				{
					type: 'SelectFieldCountry',
					label: 'Country *',
					name: 'country',
					model: 'country',
					slot: countryCodes
				},
				{
					type: 'SelectFieldState',
					label: 'Province *',
					name: 'state',
					model: 'state'
				}
			]
		}
	]
};
