<template v-if="">
	<transition name="fade">
		<div class="login-required">
			<div class="login-required__box">
				<h3 class="login-required__title">{{ $t('alert.login_required.title') }}</h3>
				<div class="login-required__message">{{ $t('alert.login_required.lead') }}</div>

				<ButtonSmall class="login-required__button button-small--alternative" @click.native="login">
					<span>{{ $t('alert.login_required.cta') }}</span>
				</ButtonSmall>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'LoginRequired',
	data() {
		return {
			sdkWindowActive: false
		};
	},
	computed: {},

	methods: {
		login() {
			if (document.documentElement.getAttribute('data-sdk-is-open') == 'true') return;
			this.userLogin();
		}
	},

	mounted() {
		this.observer = new MutationObserver(mutations => {
			for (const m of mutations) {
				const value = m.target.getAttribute(m.attributeName);
				this.sdkWindowActive = value;
			}
		});

		this.observer.observe(document.documentElement, {
			attributes: true,
			attributeOldValue: true,
			attributeFilter: ['data-sdk-is-open']
		});
	},

	beforeDestroy() {
		this.observer.disconnect();
	}
};
</script>

<style lang="scss">
$component: 'login-required';

.#{$component} {
	align-items: center;
	display: flex;
	justify-content: center;
	left: 50%;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);

	&__box {
		background-color: var(--content-background-opaque);
		border: 2px solid rgb(var(--primary-color));
		color: rgb(var(--primary-color));
		min-width: 320px;
		padding: var(--global-spacing);
		text-align: center;
	}

	&__title {
		margin-bottom: 25px;
	}

	&__message {
		margin-bottom: 25px;

		font-size: 14px;
	}

	&__button {
		margin-bottom: 10px;
		width: 150px;
		// background-color: red !important;
	}

	@include md-lg {
		&__box {
			min-width: 540px;
			width: 25%;
		}

		&__button {
			width: 150px;
		}
	}

	@include sm {
		padding: $global-spacing;

		&__box {
			max-width: 540px;
		}
	}
}
</style>
