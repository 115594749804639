<template>
	<transition name="fade">
		<div class="shop-modal" v-if="toggleModal">
			<div class="shop-modal__box">
				<h3 class="shop-modal__title">{{ titleFormatted }}</h3>
				<div class="shop-modal__message" v-if="modalMessage" v-html="formatModalMessage"></div>

				<template v-if="this.$errorCode === 6010">
					<ButtonSmall
						class="shop-modal__button"
						isOrange
						v-if="isDecisionModal"
						@click.native="makeDecision"
					>
						<span>{{ $errorResponseData.label_confirm || $t('label.cart.cancel') }}</span>
					</ButtonSmall>

					<ButtonSmall
						class="shop-modal__button shop-modal__button--cancel button-small--alternative"
						@click.native="$fireErrorEvent()"
					>
						<span>{{ $errorResponseData.label_cancel || $t('label.cart.continue_shopping') }}</span>
					</ButtonSmall>
				</template>
				<template v-else>
					<ButtonSmall
						class="shop-modal__button button-small--alternative"
						v-if="isDecisionModal"
						@click.native="makeDecision"
					>
						<span>{{ $errorResponseData.label_confirm || $t('label.cart.continue_shopping') }}</span>
					</ButtonSmall>

					<ButtonSmall
						class="shop-modal__button shop-modal__button--cancel"
						@click.native="$fireErrorEvent()"
						v-if="this.$errorCode !== 6010 && $errorResponseData.label_cancel != false"
					>
						<span>{{ $errorResponseData.label_cancel || 'Sluiten' }}</span>
					</ButtonSmall>
				</template>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'ShopModal',

	watch: {
		$errorCode(val) {
			const showError = val != 0;
			this.$store.commit('setModalActive', showError);
		}
	},

	computed: {
		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		ossConfig() {
			return this.$store.getters.getOssConfig;
		},

		isDecisionModal() {
			/*
			 * ALL 6000 ERRORS ARE DESCISION ERRORS  CLOSE / PROCEED
			 */
			return this.$errorCode >= 6000 && this.$errorCode < 7000;
		},

		toggleModal() {
			return this.$store.getters.getModalActive;
		},

		responseMessage() {
			return this.$errorResponseData && (this.$errorResponseData.message || '');
		},

		messageTitle() {
			if (this.$errorCode === 8003) {
				return this.$store.getters.getModalTitle;
			}

			return this.$errorResponseData.title;
		},

		titleFormatted() {
			if (this.ossConfig.buisnesUnit && this.messageTitle) {
				return this.messageTitle.replace(/{brand}/g, this.ossConfig.buisnesUnit);
			} else {
				return 'Er is iets misgegaan';
			}
		},

		modalMessage() {
			//This enabled the possiblity to create a model with an error response from an API request
			//example: this.$fireErrorEvent('your error');
			if (typeof this.$errorCode !== 'number') {
				return this.$errorCode;
			}

			return [8000, 8001, 8003].includes(this.$errorCode)
				? this.$store.getters.getModalMessage
				: this.responseMessage;
		},

		formatModalMessage() {
			if (this.$errorCode === 6011) {
				return `${this.modalMessage}, cartId:${this.shopCart.id}`;
			}

			return this.shopConfig
				? this.modalMessage.replace('{festivalId}', this.shopConfig.festivalData.title)
				: `${this.modalMessage}`;
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		}
	},

	methods: {
		cancelDecision() {
			console.log('cancel');
		},
		makeDecision() {
			this.$fireErrorResolvePromis('done');
		}
	}
};
</script>

<style lang="scss">
$component: 'shop-modal';

.#{$component} {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 100000;

	width: 100vw;
	height: 100vh;

	background: rgba(var(--content-background-rgb), 0.9);

	&__box {
		// min-width: 320px;
		padding: 30px;

		text-align: center;

		border: 1px solid #5e5e66;
		border-radius: 2px;
		box-shadow: inset 0px 0px 0px 2px $color-black;
		background-image: linear-gradient(
			180deg,
			var(--content-background-dark) 0%,
			var(--content-background-light-2) 100%
		);
	}

	&__title {
		margin-bottom: 25px;
	}

	&__message {
		margin-bottom: 25px;

		font-size: 14px;
	}

	&__button {
		width: 150px;
		margin-bottom: 10px;
		// background-color: red !important;
	}

	@include md-lg {
		&__box {
			width: 25%;
			min-width: 540px;
		}

		&__button {
			width: 150px;
		}
	}

	@include sm {
		padding: $global-spacing;

		&__box {
			max-width: 540px;
		}
	}
}
</style>
