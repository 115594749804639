import moment from 'moment';

export const state = () => ({
	data: null,
	paylogicToken: null,
	paymentProvider: 'paylogic',
	cartId: null,
	cart: null,
	cartHasShopifyProduct: false,
	cartFromDeeplink: false,
	travelCart: null,
	paymentMethods: null,
	paymentmethodsBleep: null,
	experienceBuilderoptions: null,
	ticketMetaData: null,
	extraMetaData: null,
	compareTransportMetaData: null,
	merchandiseMetaData: null,
	membershipMetaData: null,
	packageMetaData: null,
	notusedData: null,
	shopToken: null,
	policyId: null,
	customTranslations: {},
	showOverview: true,
	isOpen: false
});

export const mutations = {
	setCartHasShopifyProduct(state, payload) {
		if (typeof payload === 'boolean') state.cartHasShopifyProduct = payload;
	},

	setIsOpen(state, payload) {
		typeof payload === 'boolean' && (state.isOpen = payload);
	},

	setPaymentProvider(state, payload) {
		state.paymentProvider = payload;
	},
	setCart(state, payload) {
		state.cart = payload;
	},
	setCartFromDeeplink(state, payload) {
		state.cartFromDeeplink = payload;
	},

	setTravelCart(state, payload) {
		state.travelCart = payload;
	},

	setData(state, payload) {
		state.data = payload;
		// ugly fix for shopToken being read and written from differen sources
		if (payload.shopToken) {
			state.shopToken = payload.shopToken;
		}
	},

	setPaylogictoken(state, payload) {
		// console.log('setPaylogictoken', payload);
		state.paylogicToken = payload;
	},

	setPaymentmethods(state, payload) {
		state.paymentMethods = payload;
	},

	setPaymentmethodsBleep(state, payload) {
		state.paymentmethodsBleep = payload;
	},

	setExperienceBuilderoptions(state, payload) {
		state.experienceBuilderoptions = payload;
	},

	setTicketMetaData(state, payload) {
		state.ticketMetaData = payload;
	},

	setExtraMetaData(state, payload) {
		state.extraMetaData = payload;
	},

	setCompareTransportMetaData(state, payload) {
		state.compareTransportMetaData = payload;
	},

	setMerchandiseMetaData(state, payload) {
		state.merchandiseMetaData = payload;
	},

	setMembershipMetaData(state, payload) {
		state.membershipMetaData = payload;
	},

	setPackageMetaData(state, payload) {
		state.packageMetaData = payload;
	},

	setNotusedMetaData(state, payload) {
		state.notusedData = payload;
	},

	setShopToken(state, payload) {
		state.shopToken = payload;
		if (state.data) {
			// ugly fix for shopToken being read and written from differen sources
			state.data.shopToken = payload;
		}
	},

	setResetShop(state) {
		state.data = null;
		state.cart = null;
		state.paymentMethods = null;
		state.paylogicToken = null;
		state.experienceBuilderoptions = null;
		state.ticketMetaData = null;
		state.extraMetaData = null;
		state.merchandiseMetaData = null;
	},
	setPolicyId(state, payload) {
		state.policyId = payload;
	},

	setShowOverview(state, payload) {
		state.showOverview = Boolean(payload);
	},

	setCustomTranslations(state, payload) {
		if (payload) {
			const safe_translations = JSON.stringify(payload);
			state.customTranslations = safe_translations;
		}
	}
};

export const getters = {
	/* getShopNeedsSso: state => {
		return state.data.membersale || state.data.preregistration || state.data.travelsale || state.data.loginRequired;
	}, */
	getData: state => state.data,
	getEventYear: state => new moment(state.data.festivalData.dateStart).year(),
	getCart: state => {
		if (state.cart) {
			// Prevent empty cart from showing default service costs:
			return {
				...state.cart,
				totalAmount: state.cart && Object.keys(state.cart.products).length ? state.cart.totalAmount : 0
			};
		}

		return state.cart;
	},

	getEventDateFormat: state => state.data?.shopSettings?.fields?.eventDatetimeFormat,
	getCartHasShopifyProduct: state => state.cartHasShopifyProduct,
	getCartFromDeeplink: state => state.cartFromDeeplink,
	getShopTitle: state => state.data?.title,
	getIsShopOpen: state => state.isOpen,
	getShopToken: state => state.shopToken,
	getUseSalesOptions: state => state?.data?.productsInSalesOptions || false,
	getShowProgressBar: state => state?.data?.showProgressBar ?? false,
	getSalesOptions: state => state?.data?.salesOptions ?? [],
	getTravelCart: state => state.travelCart,
	getPaymentProvider: state => state.paymentProvider,
	getPaylogictoken: state => state.paylogicToken,
	getProducts: state => state?.data?.products,
	getPaymentmethods: state => state.paymentMethods,
	getPaymentmethodsBleep: state => state.paymentmethodsBleep,
	getExperienceBuilderoptions: state => state.experienceBuilderoptions,
	getTicketMetaData: state => state.ticketMetaData,
	getExtraMetaData: state => state.extraMetaData,
	getCompareTransportMetaData: state => state.compareTransportMetaData,
	getMerchandiseMetaData: state => state.merchandiseMetaData,
	getMembershipMetaData: state => state.membershipMetaData,
	getPackageMetaData: state => state.packageMetaData,
	getNotusedData: state => state.notusedData,
	getHideTicketMinMax: state => state.data?.shopSettings?.fields?.hideTicketMinMax,
	// getHasUpgradeModule: state => state.data && !!state.data.upgradableOrder
	getHasUpgradeModule: state => state.data && state.data.salesOptions.some(option => option.option === 'upgrade'),
	getIgnoreOrderFee: (state, getters, rootState, rootGetters) => false,
	// getIgnoreOrderFee: (state, getters, rootState, rootGetters) => rootGetters.getBrand === 'thunderdome'
	getPolicyId: state => state.policyId,
	getCustomTranslations: state =>
		state.customTranslations && Object.keys(state.customTranslations).length
			? JSON.parse(state.customTranslations)
			: {},
	getShowOverview: state => state.showOverview,
	getHideLogin: state => state.data?.shopSettings?.fields?.hideLogin
};
