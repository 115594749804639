<template>
	<div
		class="merchandise-item"
		:class="{
			'merchandise-item--upsell': data.isUpsell,
			'merchandise-item--highlight': data.isHighlight,
			'merchandise-item--mobile': isMobile
		}"
	>
		<div class="merchandise-item__slide-visual-container">
			<VueSlickCarousel v-bind="carouselSettings" ref="slider">
				<template v-if="data.meta.productImages && data.meta.productImages.length">
					<div class="merchandise-item__slide">
						<img
							:src="data.meta.image ? cdnUrl(data.meta.image) : 'images/product-image-fallback.jpg'"
							:alt="data.meta.description"
						/>
					</div>
					<div class="merchandise-item__slide" v-for="itemUrl in data.meta.productImages" :key="itemUrl">
						<img :src="cdnUrl(itemUrl)" :alt="data.meta.description" />
					</div>
				</template>
				<template v-else>
					<div class="merchandise-item__slide">
						<img
							:src="data.meta.image ? cdnUrl(data.meta.image) : 'images/product-image-fallback.jpg'"
							:alt="data.meta.description"
						/>
					</div>
				</template>

				<template #prevArrow>
					<button
						class="merchandise-item__slider-button merchandise-item__slider-button--previous"
						@click="onSlidePrev"
					>
						<IconArrow />
					</button>
				</template>
				<template #nextArrow>
					<button
						class="merchandise-item__slider-button merchandise-item__slider-button--next"
						@click="onSlideNext"
					>
						<IconArrow />
					</button>
				</template>
			</VueSlickCarousel>
			<UpsellItemInfo :info="data.meta.description" />
		</div>
		<div class="merchandise-item__content">
			<h3 class="merchandise-item__title">{{ data.name }}</h3>

			<div class="merchandise-item__price-details">
				<div class="merchandise-item__price-details-inner">
					<span class="merchandise-item__price" v-if="showsoldoutWithPrice">
						{{ data.price | formatPrice }}
					</span>

					<span class="merchandise-item__stock" v-if="selectedSizemeta">
						<span v-if="selectedSizemeta.stockAvailable > 0">
							<In-stock class="merchandise-item__stock-icon" />
							{{ $t('label.in_stock') }}
						</span>

						<span v-else>
							<Out-stock class="merchandise-item__stock-icon merchandise-item__stock-icon--out" />
							{{ $t('label.sold_out') }}
						</span>
					</span>
				</div>
			</div>

			<div class="merchandise-item__description" v-html="data.meta.description"></div>

			<!-- <p class="merchandise-item__description" v-html="'Printed Primal Energy logo on the front. Printed Defqon.1 logo on the back.<br/><br>This item is only available during the official Defqon.1 ticket sale.<br/><br/>Material: 100% cotton<br/>Color: black / red / light grey<br/><br/><strong>DELIVERY TIME: UP TO 10 WEEKS</strong>'"></p> -->

			<ul class="merchandise-item__size-selector">
				<li class="merchandise-item__size-item" v-for="item in data.sizes" :key="item.id">
					<button
						class="merchandise-item__size"
						:class="{
							'merchandise-item__size--soldout': item.stock <= 0,
							'merchandise-item__size--selected': item.id === selectedSize && item.stock > 0
						}"
						:disabled="item.id === selectedSize || item.stock <= 0"
						@click="userSelectedSize = item.id"
					>
						{{ item.size }}
					</button>
				</li>
			</ul>

			<InputNumberField
				class="merchandise-item__input"
				v-model="numberOfProducts"
				:isDisabled="isShopUpdatePending"
				:stock="selectedSizemeta.stockAvailable"
				:max-value="selectedSizemeta.maxQuantity"
			/>
		</div>
	</div>
</template>

<script>
import InputNumberField from '@/components/ui/InputNumberField';
import UpsellItemInfo from '@/components/shop/UpsellItemInfo';

export default {
	name: 'MerchandiseItem',

	components: {
		InputNumberField,
		UpsellItemInfo
	},

	props: {
		data: Object,
		isMobile: Boolean
	},

	data() {
		return {
			userSelectedSize: null,
			consentGiven: false,
			carouselSettings: {
				arrows: true,
				dots: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				swipeToSlide: true,
				infinite: true
			}
		};
	},

	computed: {
		showsoldoutWithPrice() {
			if (this.selectedSizemeta.stockAvailable <= 0) {
				if (!this.shopConfig.soldoutPrice) {
					return false;
				}
				return true;
			}
			return true;
		},
		firstAvailableSizeId() {
			const firstItem = this.data.sizes.find(size => size.stock > 0);
			return firstItem ? firstItem.id : this.data.id;
		},

		selectedSize() {
			return this.userSelectedSize || this.firstAvailableSizeId || this.data.id;
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		isShopUpdatePending() {
			return this.$store.getters['shopapi/getIsShopUpdatePending'];
		},

		getMappedDataFromCart() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		numberOfProducts: {
			get() {
				return this.shopCart.products[this.selectedSize] || 0;
			},

			set(val) {
				// this.$store.commit('ui/setInputNumber', parseInt(val));

				const payload = {
					url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,

					params: {
						productId: this.selectedSize,
						quantity: val
					}
				};

				const tempData = {
					name: this.data.name,
					price: this.data.price,
					productId: this.selectedSize,
					amount: val - this.numberOfProducts,
					shop: this.$store.getters['shopconfig/getData'].titleFull,
					type: this.data.mappedType
				};
				const hasVariant = this.data.sizes.find(size => this.selectedSize == size.id);
				hasVariant && (tempData.variant = hasVariant.size);
				if (this.data.meta.ageCheck) {
					if (!this.consentGiven) {
						this.$fireErrorEventWithPromis(6006).then(response => {
							this.consentGiven = true;
							this.addToCart(payload);
						});
					} else if (this.consentGiven) {
						this.addToCart(payload);
					}
				} else {
					this.addToCart(payload);
				}
			}
		},

		merchandiseData() {
			return this.$store.getters['shopapi/getProducts'] && this.$store.getters['shopapi/getProducts'].merchandise;
		},

		selectedSizemeta() {
			return this.merchandiseData.find(item => item.id === this.selectedSize);
		}
	},

	methods: {
		addToCart(payload) {
			this.$store
				.dispatch(`shopapi/putRequest`, payload)
				.then(response => {
					if (this.data.isUpsell && window.dataLayer) {
						window.dataLayer.push({
							event: 'upsellClick',
							eventLabel: `${this.shopConfig.festivalData.title}${
								this.data.isCart ? ' - Cart' : ' - Overlay'
							} `,
							eventCategory: this.data.name,
							eventAction: 'Add to cart'
						});
					}
				})
				.catch(error => {
					this.apiErrorMsg =
						error.response &&
						error.response.data &&
						error.response.data.error &&
						error.response.data.error.message;

					if (this.apiErrorMsg) {
						this.$store.commit('setModalMessage', error.response.data.error.message); // message that gets shown by 8000 error modal
						this.$fireErrorEvent(8000);
					} else {
						const errorText = this.$t('generalError.body');
						const formatedError = this.apiErrorMsg
							? `${error.response.data.error.message}, cartId:${this.shopCart.id}`
							: `${errorText} cartId:${this.shopCart.id}`;
						this.$fireErrorEvent(formatedError);
					}
				});
		},

		onSlidePrev() {
			// CAROUSEL
		},

		onSlideNext() {
			// CAROUSEL
		}
	},

	created() {
		// CAROUSEL
	},

	mounted() {
		/*
		 * Get the first merchandise item from the cart, and set this as the selectedSize;
		 */
		if (this.getMappedDataFromCart.merchandise) {
			this.getMappedDataFromCart.merchandise.forEach(item => {
				const itemInCart = this.data.sizes.find(size => size.id === item.type.id);
				if (itemInCart) {
					this.userSelectedSize = itemInCart.id;
					return;
				}
			});
		}
	}
};
</script>

<style lang="scss">
$component: 'merchandise-item';

.#{$component} {
	display: flex;
	flex-direction: column;

	height: 100%;

	background-color: var(--card-bg-color);
	// border: 2px solid $color-white;

	color: var(--primary-body-color);

	// box-shadow: inset 0px 0px 0px 2px #C5C5C5;
	// padding: 4px;

	&--highlight {
		width: 200px;
		background: var(--card-bg-color) !important;

		.#{$component} {
			&__title {
				color: var(--primary-body-color);
			}
			&__stock {
				color: var(--primary-body-color);
			}

			&__price {
				color: var(--primary-body-color);
			}

			&__size {
				&--selected {
					color: var(--primary-body-color);
					background-color: rgba(var(--button-alternative-background-color), 1);
				}
			}
			&__input {
				.input-number-field__icon {
					background-color: rgba(var(--button-alternative-background-color), 1);
					color: var(--button-alternative-text-color);
					> span {
						color: $color-black !important;
					}
				}
			}

			.input-number-field__input-wrapper {
				height: 30px;
			}
		}
	}

	&:not(&--upsell) {
		.#{$component} {
			&__info-btn,
			&__info-desc {
				display: none;
			}
		}
	}

	&--mobile {
		display: flex;
		flex-direction: column;

		margin-right: 24px;
		border: none;

		box-shadow: inset 0px 0px 0px 2px var(--primary-color);
		padding: 4px;

		width: 60vw;
		// height: 328px;

		.upsell-item-info {
			display: block;
		}

		.#{$component} {
			&__content {
				flex: auto;
				padding: 5px;
			}

			&__title {
				font-size: 14px;
				width: 99%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&__price-details {
				flex: auto;
			}
			&__price-details-inner {
				display: flex;
				justify-content: space-between;
			}

			&__stock {
				align-items: flex-end;

				padding-bottom: 2px;
			}

			&__price {
				padding-top: 0;
			}

			&__description {
				display: none;
			}

			&__size-selector {
				padding-bottom: 10px;
			}

			&__size {
				// color: var(--primary-color);
				color: var(--button-alternative-text-color);
			}
		}

		.input-number-field__input-wrapper {
			height: 30px;
		}
	}

	&--upsell {
		display: flex;
		flex-direction: column;

		margin-bottom: 0;
		border: none;

		box-shadow: inset 0px 0px 0px 2px var(--primary-color);
		padding: 4px;

		max-width: 200px;
		// height: 328px;

		.#{$component} {
			&__content {
				flex: auto;
				padding: 5px;
			}

			&__title {
				font-size: 14px;
				width: 99%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&__price-details {
				flex: auto;
			}
			&__price-details-inner {
				display: flex;
				justify-content: space-between;
			}

			&__stock {
				align-items: flex-end;

				padding-bottom: 2px;
			}

			&__price {
				padding-top: 0;
			}

			&__description {
				display: none;
			}

			&__size-selector {
				padding-bottom: 10px;
			}

			&__size {
				// color: var(--primary-color);
				color: var(--button-alternative-text-color);
			}

			&__slider {
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}

			&__slider-button {
				right: 5px;
				bottom: 15px;

				width: 30px;
				height: 30px;

				padding: 6px 8px 6px 6px;
				z-index: 100;

				&--previous {
					right: 40px;
				}
			}
		}

		.input-number-field__input-wrapper {
			height: 30px;
		}
	}

	&__content {
		flex: auto;
		display: flex;
		flex-direction: column;

		padding: 15px;
	}

	&__visuals-container {
		position: relative;
	}

	&__slider-container {
		position: relative;
		width: 100%;
		height: 0;
		padding-top: 100%;
	}

	&__slider {
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}

	&__slide {
		width: 100%;

		margin-right: 10px;
	}

	&__slider-button {
		position: absolute;

		right: 10px;
		bottom: 10px;

		width: 40px;
		height: 40px;

		padding: 10px 13px 10px 10px;
		z-index: 100;

		background-color: rgba(var(--primary-color), 0.4);

		&--previous {
			right: 60px;
		}

		&--next {
			transform: rotate(180deg);
		}

		svg {
			width: 100%;
			height: 100%;
		}

		html.no-touchevents & {
			transition: background-color 0.3s $ease-out-cubic;

			&:hover {
				background-color: rgba(var(--primary-color), 1);
			}
		}
	}

	&__slide-visual-container {
		position: relative;
	}

	&__slide-visual {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		@include object-fit(cover, center);
	}

	&__image {
		line-height: 0;

		img {
			width: 100%;
			max-width: unset;
		}
	}

	&__title {
		font-size: 20px;
		text-transform: uppercase;
		font-family: $font-regular;

		@include sm {
			font-size: 18px;
			word-wrap: break-word;
			hyphens: auto;
		}
	}

	&__price {
		display: block;
		padding-top: 25px;
	}

	&__stock {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-family: $font-bold;
		font-size: 12px;
	}

	&__description {
		flex: auto;
		padding: $global-spacing 0 $global-spacing * 1.5;

		font-size: 14px;
		line-height: 1.2;
	}

	&__size-selector {
		display: flex;
		justify-content: space-between;
		padding-bottom: 20px;
	}
	&__size-item {
		width: 100%;
		height: 30px;

		&:not(:last-child) {
			padding-right: 7px;
		}
	}

	&__size {
		width: 100%;
		height: 100%;

		font-size: 14px;
		background-color: rgba(var(--button-alternative-background-color), 1);
		border: 1px solid #b0b0b0;
		color: var(--button-alternative-text-color);
		&--soldout {
			background-color: #d9d9d9;
			color: #a8a8a8 !important;
		}

		&--selected {
			border: none;
			// color: $color-white;
			background-color: rgba(var(--primary-color), 1);
			color: var(--button-alternative-text-color);
		}

		&:disabled {
			cursor: default;
		}
	}

	&__stock-icon {
		width: 10px;
		path {
			fill: green;
		}

		&--out {
			path {
				fill: red;
			}
		}
	}
}
</style>
