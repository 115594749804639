// import ErrorCodes from '@/static/data/errorCodes.json';

export default {
	install(Vue) {
		let currentError = new Vue({
			data: {
				$errorCode: 0,
				$promiseResolve: null,
				$promiseReject: null
			}
		});

		Vue.mixin({
			/*
			 * Lets handle all the errors thrown from the shop
			 *
			 */
			computed: {
				locale() {
					return this.$store.getters.getLocale;
				},

				$promiseResolve() {
					return currentError.$data.$promiseResolve;
				},

				$promiseReject() {
					return currentError.$data.$promiseReject;
				},

				$errorCode() {
					return currentError.$data.$errorCode;
				},

				$errorResponseData() {
					const errorData =
						this.$i18n.messages?.[this.locale.code]?.error?.code || this.$store.getters.getErrorcodes;

					if (this.$te(`error${currentError.$data.$errorCode}`)) {
						return this.$t(`error${currentError.$data.$errorCode}`);
					} else {
						return errorData[currentError.$data.$errorCode] || errorData[0];
					}
				}
			},

			methods: {
				$fireErrorEvent(errorCode) {
					errorCode = errorCode != undefined ? errorCode : 0;
					currentError.$data.$errorCode = errorCode;

					const errorData =
						this.$i18n.messages?.[this.locale.code]?.error?.code || this.$store.getters.getErrorcodes;

					return errorData[currentError.$data.$errorCode] || errorData[0];
				},

				$fireErrorEventWithPromis(errorCode) {
					return (this.promise = new Promise((resolve, reject) => {
						currentError.$data.$promiseResolve = resolve;
						currentError.$data.$promiseReject = reject;

						const errorData =
							this.$i18n.messages?.[this.locale.code]?.error?.code || this.$store.getters.getErrorcodes;

						errorCode = errorCode != undefined ? errorCode : 0;
						currentError.$data.$errorCode = errorCode;
						// errorCode != 0 &&
						// 	console.error(
						// 		`${errorCode}: ${errorData[errorCode].title}. ${errorData[errorCode].message || ''}`
						// 	);
					}));
				},

				$fireErrorResolvePromis(val) {
					currentError.$data.$errorCode = 0;
					currentError.$data.$promiseResolve();
				}
			}
		});
	}
};
