import { throttle } from '@/utils/throttle';

/**
 * Middleware function that handles various tasks before and after route navigation.
 * @docs https://v2.nuxt.com/docs/directory-structure/middleware/#router-middleware*
 * @param {Object} options - The options object.
 * @param {Object} options.app - The Vue app instance.
 * @param {Object} options.req - The request object.
 * @param {Object} options.route - The current route object.
 * @param {Object} options.store - The Vuex store instance.
 * @param {Function} options.redirect - The redirect function.
 * @param {Object} options.query - The query parameters.
 * @returns {void}
 */
export default function ({ app, req, route, store, redirect, query }) {
	if (query.deeplink) {
		const deeplink = {
			path: query.deeplink,
			category: query.category || null,
			raw: `${route.fullPath.split('?')[1]}`
		};

		app.$cookies.remove(`shop_deeplink`);
		app.$cookies.set(`shop_deeplink`, JSON.stringify(deeplink), {
			path: '/',
			maxAge: 15 * 60 // 15 minutes
		});

		// remove deeplink from query
		delete query.deeplink;
		delete query.category;
		app.router.push({ route });
	}

	//If user lands on subpages redirect back to main event page
	query.token && store.commit('shopconfig/setPaylogictoken', query.token);
	query.cartToken && store.commit('shopconfig/setShopToken', query.cartToken);
	query.policyId && store.commit('shopconfig/setPolicyId', query.policyId);
	query.cartId && store.commit('setCartId', query.cartId);

	if (route.name !== 'slug-checkout' && query.cartToken && query.cartId) {
		store.commit('shopconfig/setCartFromDeeplink', true);
	}

	store.commit('setBrand', process.env.SHOPBRAND);

	const shopData = store.getters['shopconfig/getData'];
	const isSsoInited = store.getters['sso/getIsSsoInited'];
	const isUserLoggedIn = store.getters['sso/getIsUserLoggedIn'];

	query.token && store.commit('shopconfig/setPaylogictoken', query.token);
	query.cartToken && store.commit('shopconfig/setShopToken', query.cartToken);
	query.policyId && store.commit('shopconfig/setPolicyId', query.policyId);
	query.cartId && store.commit('setCartId', query.cartId);

	// wishlist deeplink
	if (query.getwishlist && query.hash && query.eventid && query.uid && !isUserLoggedIn) {
		store
			.dispatch('sso/fetchtWishlistFromUrl', {
				eventId: query.eventid,
				userId: query.uid,
				verifier: query.hash
			})
			.then(() => {
				const qp = { ...query };
				delete qp.getwishlist;
				delete qp.hash;
				delete qp.eventid;
				delete qp.uid;
				app.router.push({ path: route.path, query: qp });
			});
	}

	/*
	 * If not membersale prevent user form login-out within active session
	 * If user gets this screen something is really wrong :-)
	 */
	if (
		(shopData && shopData.membersale) ||
		(shopData && shopData.preregistration) ||
		(shopData && shopData.travelsale) ||
		(shopData && shopData.addonShop)
	) {
		if (isSsoInited && !isUserLoggedIn && route.path != '/login') {
			redirect('/login');
			return;
		}
	}

	if (
		req &&
		route.params.slug &&
		shopData === null &&
		route.path.split('/')[2] != 'paymentstatus' &&
		route.path.split('/')[2] != 'checkout'
	) {
		// const newRoute = route.params.slug ? `/${route.params.slug}` : '/';
		// return redirect(newRoute, query);

		/* app.$cookies.set('test', query.idt_oss_order_id, {
			path: '/',
			maxAge: 3600
		}); */

		return redirect({
			name: 'slug',
			params: route.params,
			query: query
		});
	}
}
