const createItemsArray = (cartProducts, brand) => {
	try {
		const itemsArray = [];
		const contents = [];
		const contentAdditional = [];
		const contentAdditional2 = [];
		for (const key in cartProducts) {
			const dataArray = cartProducts[key];
			if (key != 'travel' && key != 'addon') {
				for (let i = 0; i < dataArray.length; i++) {
					const currentItem = dataArray[i];
					if (currentItem.type.name !== 'Order fee') {
						const item = {
							item_id: currentItem.type.id,
							item_name: currentItem.type.name,
							affiliation: '',
							coupon: '',
							discount: 0,
							item_brand: brand,
							item_category: currentItem.type.type,
							price: currentItem.type.price,
							quantity: isNaN(currentItem.numproducts)
								? currentItem.numproducts
								: parseInt(currentItem.numproducts)
						};
						const contentsItem = {
							id: currentItem.type.id,
							quantity: isNaN(currentItem.numproducts)
								? currentItem.numproducts
								: parseInt(currentItem.numproducts)
						};
						const contentAdditionalItem = {
							content_id: currentItem.type.id,
							content_name: currentItem.type.name,
							price: currentItem.type.price,
							quantity: isNaN(currentItem.numproducts)
								? currentItem.numproducts
								: parseInt(currentItem.numproducts)
						};
						contentAdditional.push(contentAdditionalItem);
						contents.push(contentsItem);
						itemsArray.push(item);
					}
				}
			}

			if (key === 'addon') {
				for (let i = 0; i < dataArray.length; i++) {
					const currentItem = dataArray[i];
					for (const id in currentItem) {
						if (currentItem[id].type.name !== 'Order fee') {
							const item = {
								item_id: currentItem[id].type.id,
								item_name: currentItem[id].type.name,
								affiliation: '',
								coupon: '',
								discount: 0,
								item_brand: brand,
								item_category: currentItem[id].type.type,
								price: currentItem[id].type.price,
								quantity: isNaN(currentItem[id].numproducts)
									? currentItem[id].numproducts
									: parseInt(currentItem[id].numproducts)
							};
							const contentsItem = {
								id: currentItem[id].type.id,
								quantity: isNaN(currentItem[id].numproducts)
									? currentItem[id].numproducts
									: parseInt(currentItem[id].numproducts)
							};
							const contentAdditionalItem = {
								content_id: currentItem[id].type.id,
								content_name: currentItem[id].type.name,
								price: currentItem[id].type.price,
								quantity: isNaN(currentItem[id].numproducts)
									? currentItem[id].numproducts
									: parseInt(currentItem[id].numproducts)
							};
							contentAdditional.push(contentAdditionalItem);
							contents.push(contentsItem);
							itemsArray.push(item);
						}
					}
				}
			}

			if (key === 'travel') {
				for (let i = 0; i < dataArray.length; i++) {
					const currentItem = dataArray[i];
					const item = {
						item_id: `${currentItem.package.eventId}_${currentItem.package.accommodationId}`,
						item_name: currentItem.package.name,
						affiliation: '',
						coupon: '',
						discount: 0,
						item_brand: brand,
						item_category: 'travel',
						price: currentItem.packageOption.subtotal,
						quantity: 1
					};
					const contentsItem = {
						id: `${currentItem.package.eventId}_${currentItem.package.accommodationId}`,
						quantity: 1
					};
					const contentAdditionalItem = {
						content_id: `${currentItem.package.eventId}_${currentItem.package.accommodationId}`,
						content_name: currentItem.package.name,
						price: currentItem.packageOption.subtotal,
						quantity: 1
					};
					const contentAdditionalItem2 = {
						item_category: currentItem.package.name,
						item_ids: `${currentItem.package.eventId}_${currentItem.package.accommodationId}`,
						number_items: 1
					};
					if (currentItem.extras.length) {
						currentItem.extras.forEach(item => {
							const extraItem = {
								item_id: item.extra.id,
								item_name: item.extra.name,
								affiliation: '',
								coupon: '',
								discount: 0,
								item_brand: brand,
								item_category: 'travel_extra',
								price: item.subtotal,
								quantity: item.amount
							};
							const extracontentsItem = {
								id: item.extra.id,
								quantity: item.amount
							};
							const extracontentAdditionalItem = {
								content_id: item.extra.id,
								content_name: item.extra.name,
								price: item.subtotal,
								quantity: item.amount
							};
							const contentAdditionalItem2 = {
								item_category: 'travel_extra',
								item_ids: item.extra.id,
								number_items: item.amount
							};
							contentAdditional.push(extracontentAdditionalItem);
							contents.push(extracontentsItem);
							contentAdditional2.push(contentAdditionalItem2);
							itemsArray.push(extraItem);
						});
					}
					contentAdditional2.push(contentAdditionalItem2);
					contentAdditional.push(contentAdditionalItem);
					contents.push(contentsItem);
					itemsArray.push(item);
				}
			}
		}
		return { items: itemsArray, contents: contents, contentAdditional: contentAdditional, contentAdditional2 };
	} catch (error) {
		console.error('Error in creating itemsArray:', error);
	}
};

export const state = () => ({
	userData: {}
});

export const getters = {
	getUserdata: state => state.userData
};

export const mutations = {
	setUserdata(state, payload) {
		state.userData = payload;
	}
};

export const actions = {
	addRemoveCart(context, payload) {
		try {
			//if payload is invalid return false to save computing resources
			if (!window.dataLayer && !payload.params && !payload.params.productId) {
				return;
			}
			const products = context.rootGetters['shopapi/getRawProducts'];
			const productData = products.find(item => item.id === payload.params.productId);
			const brand = context.rootGetters['sso/getSsoBrand'];
			const cartItems = context.rootGetters['shopapi/getMappedProductsInBasket'];
			const type = productData.type && productData.type !== 'bus_travel' ? productData.type : 'transportation';
			let idAlreadyincart =
				(cartItems &&
					cartItems[type] &&
					cartItems[type].find(item => item.type.id === payload.params.productId)) ||
				null;

			//check if item is not an extra. Extas and tickets both have the same type; 'ticket'.
			if (!idAlreadyincart && type === 'ticket') {
				idAlreadyincart =
					(cartItems &&
						cartItems['extras'] &&
						cartItems['extras'].find(item => item.type.id === payload.params.productId)) ||
					null;
			}
			const data = {
				brand: brand,
				productData: productData
			};

			//Check if you need to add or remove from the datalayer
			if (!idAlreadyincart || idAlreadyincart.numproducts < payload.params.quantity) {
				data.quantity = payload.datalayerQuantity ? payload.datalayerQuantity : payload.params.quantity;
				context.dispatch('addtoCart', data);
			} else {
				const quantity = idAlreadyincart.numproducts - payload.params.quantity;
				data.quantity = payload.datalayerQuantity ? payload.datalayerQuantity : quantity;
				context.dispatch('removeFromCart', data);
			}
		} catch (error) {
			console.error('Error in addRemoveCart:', error);
		}
	},

	addtoCart(context, payload) {
		try {
			const { productData, brand, quantity } = payload;
			const shopCart = context.rootGetters['shopconfig/getCart'];
			const cartTotal = shopCart.totalAmount + quantity * productData.price;
			if (productData && brand && quantity && window.dataLayer) {
				dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
				dataLayer.push({
					event: 'add_to_cart',
					ecommerce: {
						currency: 'EUR',
						value: cartTotal,
						items: [
							{
								item_id: productData.id,
								item_name: productData.name,
								affiliation: '',
								coupon: '',
								discount: 0,
								item_brand: brand,
								item_category: productData.type,
								price: productData.price,
								quantity: quantity
							}
						],
						contents: [
							{
								id: productData.id,
								quantity: quantity
							}
						],
						contentAdditional: [
							{
								content_id: productData.id,
								content_name: productData.name,
								price: productData.price,
								quantity: quantity
							}
						],
						contentAdditional2: {
							item_category: productData.type,
							item_ids: productData.id,
							number_items: quantity
						}
					}
				});
			}
		} catch (error) {
			console.error('Error in addtoCart:', error);
		}
	},

	removeFromCart(context, payload) {
		try {
			const { productData, brand, quantity } = payload;
			const shopCart = context.rootGetters['shopconfig/getCart'];
			const cartTotal = shopCart.totalAmount - quantity * productData.price;
			if (productData && brand && quantity && window.dataLayer) {
				dataLayer.push({ ecommerce: null });
				dataLayer.push({
					event: 'remove_from_cart',
					ecommerce: {
						currency: 'EUR',
						value: cartTotal,
						items: [
							{
								item_id: productData.id,
								item_name: productData.name,
								affiliation: '',
								coupon: '',
								discount: 0,
								item_brand: brand,
								item_category: productData.type,
								price: productData.price,
								quantity: quantity
							}
						]
					}
				});
			}
		} catch (error) {
			console.error('Error in removeFromCart:', error);
		}
	},

	viewCart(context, payload) {
		try {
			const brand = context.rootGetters['sso/getSsoBrand'];
			const cartItems = context.rootGetters['shopapi/getMappedProductsInBasket'];
			const cartProducts = context.rootGetters['shopapi/getMappedProductsInBasket'];
			const cart = context.rootGetters['shopconfig/getCart'];

			const formatedItems = createItemsArray(cartProducts, brand);
			dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
			dataLayer.push({
				event: 'view_cart',
				ecommerce: {
					currency: 'EUR',
					value: cart.totalAmount,
					items: formatedItems.items
				},
				contents: formatedItems.contents,
				contentAdditional: formatedItems.contentAdditional,
				contentAdditional2: {
					item_category: formatedItems.contentAdditional2.map(item => item.item_category).join(','),
					item_ids: formatedItems.contentAdditional2.map(item => item.item_ids).join(','),
					number_items: formatedItems.contentAdditional2.map(item => item.number_items).join(',')
				}
			});
		} catch (error) {
			console.error('Error in viewCart:', error);
		}
	},
	beginCheckout(context, payload) {
		try {
			const brand = context.rootGetters['sso/getSsoBrand'];
			const cartProducts = context.rootGetters['shopapi/getMappedProductsInBasket'];
			const cart = context.rootGetters['shopconfig/getCart'];

			const userDataObject = {
				customer_data_name: payload.firstName,
				customer_data_last_name: payload.lastName,
				customer_data_email: payload.email,
				customer_data_phone: payload.phoneNumber,
				customer_data_postal_code: payload.postalCode,
				customer_data_city: payload.city,
				customer_data_country: payload.country,
				customer_data_region: payload.state ? payload.state : ''
			};
			context.commit('setUserdata', userDataObject);

			const formatedItems = createItemsArray(cartProducts, brand);
			dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
			dataLayer.push({
				event: 'begin_checkout',
				ecommerce: {
					currency: 'EUR',
					value: cart.totalAmount,
					items: formatedItems.items
				},
				contentsUserdata: [userDataObject],
				contents: formatedItems.contents,
				contentAdditional: formatedItems.contentAdditional,
				contentAdditional2: {
					item_category: formatedItems.contentAdditional2.map(item => item.item_category).join(','),
					item_ids: formatedItems.contentAdditional2.map(item => item.item_ids).join(','),
					number_items: formatedItems.contentAdditional2.map(item => item.number_items).join(',')
				}
			});
		} catch (error) {
			console.error('Error in beginCheckout:', error);
		}
	},

	addPaymentInfo(context, payload) {
		try {
			const brand = context.rootGetters['sso/getSsoBrand'];
			const cartProducts = context.rootGetters['shopapi/getMappedProductsInBasket'];
			const cart = context.rootGetters['shopconfig/getCart'];
			const formatedItems = createItemsArray(cartProducts, brand);
			dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
			dataLayer.push({
				event: 'add_payment_info',
				ecommerce: {
					currency: 'EUR',
					value: cart.totalAmount,
					payment_type: payload,
					items: formatedItems.items
				},
				contentsUserdata: [context.getters.getUserdata],
				contents: formatedItems.contents,
				contentAdditional: formatedItems.contentAdditional,
				contentAdditional2: {
					item_category: formatedItems.contentAdditional2.map(item => item.item_category).join(','),
					item_ids: formatedItems.contentAdditional2.map(item => item.item_ids).join(','),
					number_items: formatedItems.contentAdditional2.map(item => item.number_items).join(',')
				}
			});
		} catch (error) {
			console.error('Error in addPaymentInfo:', error);
		}
	},

	purchase(context, payload) {
		try {
			const brand = context.rootGetters['sso/getSsoBrand'];
			const cartProducts = context.rootGetters['shopapi/getMappedProductsInBasket'];
			const cart = context.rootGetters['shopconfig/getCart'];
			// const formatedItems = createItemsArray(payload.items, brand);
			const itemsArray = [];
			const contents = [];
			const contentAdditional = [];
			const contentAdditional2 = [];
			payload.items.forEach(currentItem => {
				const item = {
					item_id: currentItem.item_id,
					item_name: currentItem.item_name,
					affiliation: '',
					coupon: '',
					discount: 0,
					item_brand: brand,
					item_category: currentItem.item_category,
					price: currentItem.price,
					quantity: isNaN(currentItem.quantity) ? currentItem.quantity : parseInt(currentItem.quantity)
				};
				const contentsItem = {
					id: currentItem.item_id,
					quantity: isNaN(currentItem.quantity) ? currentItem.quantity : parseInt(currentItem.quantity)
				};
				const contentAdditionalItem = {
					content_id: currentItem.item_id,
					content_name: currentItem.item_name,
					price: currentItem.price,
					quantity: isNaN(currentItem.quantity) ? currentItem.quantity : parseInt(currentItem.quantity)
				};
				const contentAdditional2Item = {
					item_category: currentItem.item_category,
					item_ids: currentItem.item_id,
					number_items: currentItem.quantity
				};
				contentAdditional.push(contentAdditionalItem);
				contents.push(contentsItem);
				itemsArray.push(item);
				contentAdditional2.push(contentAdditional2Item);
			});

			dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
			dataLayer.push({
				event: 'purchase',
				ecommerce: {
					transaction_id: payload.tx,
					tax: payload.tax,
					shipping: payload.shipping,
					currency: 'EUR',
					value: payload.totalAmount,
					items: itemsArray
				},
				contents: contents,
				contentAdditional: contentAdditional,
				contentAdditional2: {
					item_category: contentAdditional2.map(item => item.item_category).join(','),
					item_ids: contentAdditional2.map(item => item.item_ids).join(','),
					number_items: contentAdditional2.map(item => item.number_items).join(',')
				},
				contentsUserdata: [
					{
						customer_data_name: payload.firstname || '',
						customer_data_last_name: payload.lastName || '',
						customer_data_email: payload.email || '',
						customer_data_phone: payload.phoneNumber || '',
						customer_data_postal_code: payload.postalCode || '',
						customer_data_city: payload.city || '',
						customer_data_country: payload.country || '',
						customer_data_region: payload.state ? payload.state : ''
					}
				]
			});
		} catch (error) {
			console.error('Error in purchase:', error);
		}
	}
};
