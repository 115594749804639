import preserveUTMParams from '@/utils/preserveUTMParams';
import 'vue-toast-notification/dist/theme-bootstrap.css';

export const state = () => ({
	isSsoInited: false,
	ssoElement: null,
	isLoginChecked: false,
	userData: null,
	userPreregistrationData: null,
	isPreregistrationChecked: false,
	membershipStatus: 0,
	isSsoAvailable: true,
	shownUserLoggedInDialog: false,
	wishlistConfig: {}
});

export const actions = {
	initSSO(context, element) {
		const brand =
			context.rootGetters['shopconfig/getData'] !== null
				? context.rootGetters['shopconfig/getData'].hasOwnProperty('ossBrand')
					? context.rootGetters['shopconfig/getData'].ossBrand !== 'default'
						? context.rootGetters['shopconfig/getData'].ossBrand || 'qdance'
						: context.getters.getSsoBrand
					: context.getters.getSsoBrand
				: context.getters.getSsoBrand;
		if (!brand) {
			context.commit('setIsSsoAvailable', false);
			context.commit('setIsLoginChecked', true);
			return;
		}

		if (typeof controller === 'undefined') {
			console.log('SSO Controller not defined');
			return Promise.resolve();
		}

		if (typeof element === 'undefined') {
			console.log('SSO element not defined');
			return Promise.resolve();
		}

		if (typeof brand === 'undefined') {
			console.log('SSO brand not defined');
			return Promise.resolve();
		}

		controller.setConfig({
			element: element,
			flow: 'commerceFlow',
			brand,
			showBenefits: brand === 'thunderdome'
		});

		if (!context.state.isSsoInited) {
			context.commit('setIsSsoInited', true);
			if (!controller.getLoggedInBrand() || controller.getLoggedInBrand() === brand) {
				controller
					.checkForActiveSession()
					.then(response => {
						// console.log('checkForActiveSession response:', response);
						context.commit('setUserData', response.data.data);
						context.commit('setIsLoginChecked', true);

						if (!context.getters.getShownUserLoggedInDialog) {
							setTimeout(() => {
								this._vm.$toast.open({
									message: this.$i18n.t('alert.logged_in_as', {
										name: response.data.data.profile.givenName
									}),
									type: 'default',
									dismissible: false,
									duration: 6000,
									position: 'bottom-left'
								});
							}, 1000);
						}
						context.commit('setShownUserLoggedInDialog', true);
					})
					.catch(error => {
						// console.log('checkForActiveSession error', error.response.data);
						context.commit('setIsLoginChecked', true);
					});
			} else {
				context.commit('setIsLoginChecked', true);

				if (controller.getLoggedInBrand() && controller.getLoggedInBrand() !== brand) {
					// also clear the cookies
					controller.clearCookies();
				}
			}
		}
		return Promise.resolve();
	},

	userRegister(context, payload) {
		const returnUrl = preserveUTMParams(
			`${window.location.origin}/${(payload.params && payload.params.slug) || ''}`
		);
		const paramsData = {
			token: context.rootGetters['shopconfig/getPaylogictoken'],
			cartId: context.rootGetters['shopconfig/getCart'] && context.rootGetters['shopconfig/getCart'].id,
			shopToken: context.rootGetters['shopconfig/getData'] && context.rootGetters['shopconfig/getData'].shopToken,
			idt_oss_order_id: context.rootGetters.getUpgradeOrderId,
			access_id: context.rootGetters.getAccessId
		};

		let paramsStr = '';
		Object.keys(paramsData).forEach(key => {
			if (paramsData[key]) {
				paramsStr += (paramsStr.length ? '&' : '?') + `${key}=${paramsData[key]}`;
			}
		});

		controller
			.register(`${returnUrl}${paramsStr}`)
			.then(response => {
				// This is never called
				// context.commit('setUserData', response.data.data);
			})
			.catch(error => {
				console.error('userRegister error:', error);
			});
	},

	userLogIn(context, payload) {
		if (!payload) {
			console.log(
				"Don't call sso/userLogIn action directly but use the global plugin 'this.userLogin()'. This adds the current route to the payload."
			);
		}
		const returnUrl = preserveUTMParams(
			`${window.location.origin}/${(payload.params && payload.params.slug) || ''}`
		);
		const paramsData = {
			token: context.rootGetters['shopconfig/getPaylogictoken'],
			cartId: context.rootGetters['shopconfig/getCart'] && context.rootGetters['shopconfig/getCart'].id,
			shopToken: context.rootGetters['shopconfig/getData'] && context.rootGetters['shopconfig/getData'].shopToken,
			idt_oss_order_id: context.rootGetters.getUpgradeOrderId,
			access_id: context.rootGetters.getAccessId
		};

		let paramsStr = '';
		Object.keys(paramsData).forEach(key => {
			if (paramsData[key]) {
				paramsStr += (paramsStr.length ? '&' : '?') + `${key}=${paramsData[key]}`;
			}
		});

		controller
			.login(`${returnUrl}${paramsStr}`)
			.then(response => {
				context.commit('setUserData', response.data.data);
				if (dataLayer) {
					dataLayer.push({ userId: response.data.data.id });
				}
			})
			.catch(error => {
				console.error('userLogIn error:', error);
			});
	},

	userLogOut(context) {
		controller
			.logout()
			.then(response => {
				// console.log('logout; ', response);
				context.commit('setUserData', null);
				context.commit('setuserPreregistrationData', null);
			})
			.catch(error => {
				console.error('userLogOut error:', error);
			});
	},

	editAccount(context) {
		controller
			.editAccount()
			.then(response => {
				// console.log('Response:', response);
				// context.commit('setUserData', response.data.data);
			})
			.catch(error => {
				console.error('editAccount error:', error);
			});
	},

	loadUserPreregistration(context) {
		if (
			!context.rootGetters['shopconfig/getData'].hasOwnProperty('preregistrationKey') &&
			!context.rootGetters['shopconfig/getData'].preregistrationKey
		) {
			return;
		}
		controller
			.getWishlists({
				eventId: context.rootGetters['shopconfig/getData'].preregistrationKey,
				year: context.rootGetters['shopconfig/getEventYear']
			})
			.then(response => {
				context.commit('setuserPreregistrationData', response.data.data);

				context.commit('setIsPreregistrationChecked', true);
			})
			.catch(error => {
				// console.error(
				// 	'loadPreregistrations error:',
				// 	error.response.data.error.message || error
				// );
				context.commit('setIsPreregistrationChecked', true);
			});
	},

	storeUserPreregistration(context, preferences = {}) {
		/*
		 * BE CAREFUL NOT TO OVERWRITE THE WRONG DATA IN THE PREFERENCES OBJECT!
		 * SSO Preferences object structure:
		 *
		 * preferences: {
		 * 		preregUserInput: {},
		 * 		wishlistData: {
		 * 			wishlist: {},
		 * 			travelcart: {},
		 * 			saleType: {}
		 * 		},
		 * 		isMemberSaleUsed: true/false
		 * }
		 *
		 */

		let preferencesUpdated = {};
		if (context.state.userPreregistrationData && context.state.userPreregistrationData.preferences) {
			preferencesUpdated = { ...context.state.userPreregistrationData.preferences };
		}

		if (preferences.wishlistData) {
			if (Object.keys(preferences.wishlistData).length) {
				preferencesUpdated.wishlistData = {
					...(preferencesUpdated.wishlistData || {}),
					...preferences.wishlistData
				};
			} else {
				preferencesUpdated.wishlistData = preferences.wishlistData;
			}
		}

		const payload = {
			eventId: context.rootGetters['shopconfig/getData'].preregistrationKey,
			year: context.rootGetters['shopconfig/getEventYear'],
			preferences: preferencesUpdated
		};

		return new Promise((resolve, reject) => {
			controller
				.putWishlists(payload)
				.then(response => {
					// console.log('storeUserPreregistration response: ', response);
					resolve(response);
					context.dispatch('loadUserPreregistration'); //Update local preregistrations data
				})
				.catch(error => {
					console.error('storeUserPreregistration error:', error);
					reject(error);
				});
		});
	},

	saveWishlist(context, payload) {
		// const saleType = context.rootGetters['shopconfig/getUserexperience'];
		const shopTravelCart = context.rootGetters['shopconfig/getTravelCart'];

		return context.dispatch('storeUserPreregistration', {
			wishlistData: {
				wishlist: payload,
				travelCart: shopTravelCart
				// saleType  // Not sure what to put here and if it's ever used. Relic from Gigya wishlist
			}
		});
	},

	fetchtWishlistFromUrl(context, payload) {
		if (context.getters.getIsUserLoggedIn) return;

		const url = `https://members.id-t.com/api/content/wishlists/${payload.eventId}/users/${payload.userId}/${payload.verifier}`;

		return this.$axios
			.get(url, { headers: { brand: context.getters.getSsoBrand } })
			.then(({ data: { data } }) => {
				context.commit('setuserPreregistrationData', data);
				context.commit('setIsPreregistrationChecked', true);
				context.commit('setModalPrefillCartActive', true, { root: true });
			})
			.catch(error => {
				console.log('→ error', error);
			});
	},

	/*
	 * If the user decides to change his preregistration type (ticket only vs travel) we need to clear this in the prereg preferences
	 */
	removeWishlist(context, payload) {
		context.dispatch('storeUserPreregistration', {
			wishlistData: {}
		});
	},

	subscribeNewsletterSimple(context) {
		if (context.state.userData) {
			controller.subscribeUserToNewsletter();
		} else {
			context.dispatch('subscribeNewsletter');
		}
	},

	subscribeNewsletter(context, payload) {
		controller
			.newsLetterSignup(payload)
			.then(response => {
				console.log(response.data.data);
			})
			.catch(error => {
				console.log(error.response.data);
			});
	},

	loadMembershipStatus(context) {
		controller.getMembership().then(response => {
			if (response.data && response.data.data && response.data.data.level) {
				context.commit('setMembershipStatus', response.data.data.level);
			}
		});
	}
};

export const mutations = {
	setIsSsoInited(state, payload) {
		state.isSsoInited = payload;
	},
	setSsoElement(state, payload) {
		state.ssoElement = payload;
	},

	setIsLoginChecked(state, payload) {
		state.isLoginChecked = payload;
	},

	setUserData(state, payload) {
		state.userData = payload;
	},

	setIsPreregistrationChecked(state, payload) {
		state.isPreregistrationChecked = payload;
	},

	setuserPreregistrationData(state, payload) {
		state.userPreregistrationData = payload;
	},

	setMembershipStatus(state, payload) {
		state.membershipStatus = payload;
	},

	setIsSsoAvailable(state, payload) {
		state.isSsoAvailable = payload;
	},

	setShownUserLoggedInDialog(state, payload) {
		if (typeof payload !== 'boolean') return;
		state.shownUserLoggedInDialog = payload;
	}
};

export const getters = {
	getIsSsoInited: state => state.isSsoInited,
	getIsLoginChecked: state => state.isLoginChecked,
	getIsUserLoggedIn: state => !!state.userData,
	getUserData: state => state.userData,
	getIsPreregistrationChecked: state => state.isPreregistrationChecked,
	getUserPreregistrationData: state => state.userPreregistrationData,
	getUserHasPreregistered: state =>
		state.userPreregistrationData &&
		state.userPreregistrationData.wishlistData &&
		!!state.userPreregistrationData.wishlistData.wishlistData,
	// && !!state.userPreregistrationData.preferences.wishlistData.saleType,
	getIsMemberSaleUsed: state =>
		state.userPreregistrationData &&
		state.userPreregistrationData.preferences &&
		state.userPreregistrationData.preferences.isMemberSaleUsed,
	getSsoBrand: state => {
		return {
			aod: 'artofdance',
			b2s: 'b2s',
			dgtl: 'dgtl',
			dominator: 'dominator',
			elrow: 'elrow',
			festivalmacumba: 'fiestamacumba',
			gardensofbabylon: 'gardensofbabylon',
			idt: 'idt',
			mananamanana: 'mananamanana',
			mastersofhardcore: 'mastersofhardcore',
			milkshake: 'milkshake',
			mysteryland: 'mysteryland',
			openair: 'amsterdam-open-air',
			pleinvrees: 'pleinvrees',
			qdance: 'qdance',
			sensation: 'sensation',
			snakepit: 'snakepit',
			strafwerk: 'strafwerk',
			supremacy: 'supremacy',
			thunderdome: 'thunderdome',
			vunzigedeuntjes: 'vunzigedeuntjes',
			wttf: 'welcometothefuture',
			zwartecross: 'zwartecross'
		}[process.env.SHOPBRAND];
	},
	getWishlist: state =>
		state.userPreregistrationData &&
		state.userPreregistrationData.wishlistData &&
		state.userPreregistrationData.wishlistData.wishlistData,
	getIsSsoAvailable: state => state.isSsoAvailable,
	getMembershipStatus: state => state.membershipStatus,
	getShownUserLoggedInDialog: state => state.shownUserLoggedInDialog
};
