import LocaleLanguage from '@/models/LocaleLanguage';
export const strict = false;

export const state = () => ({
	hasConfig: true, // false if no config found in CTF
	ossConfig: null,
	showCheckout: false,
	showCheckoutForm: false,
	showCounter: false,
	readyToshop: false,
	modalActive: false,
	modalReplacementActive: false,
	modalPrefillCartActive: false,
	modalTitle: '',
	modalMessage: '',
	cartUpdate: false,
	cartUpdateTitle: '',
	cartUpdateMessage: '',
	isTouch: false,
	brand: null,
	mainBackgroundImage: null,
	brandLogo: null,
	shopRoutingFlow: null,
	cartId: null,
	upgradeOrderId: '', // Used for upgrading an order. User enters shop from an email with an orderid in the link
	accessId: '', // Used to limit access to the shop. User enters shop from an email with an access_id in the link
	isWishlistSaved: false,
	isUpgradeChoiceMade: false,
	isTermsAccepted: false,
	isCovidTermsAccepted: false,
	prebuilderChoice: '',
	errorCodes: null,
	deepLinkQueryString: null,
	fromTravelDetailPath: null,
	lookUpState: 'idle', // idle || running || success || invalid || no-order || failed
	replacementName: '',
	replacementAmount: 0,
	compareTransportCat: '',
	statictranslationJson: null,
	locale: null,
	customTranslations: {},
	countryCodes: {},
	countryPhoneCodes: {}
});

export const actions = {
	getShopinit(context, payload) {
		let postData = {
			url: '/shop',
			method: 'GET',
			config: {
				headers: {}
			}
		};

		postData.url = `${process.env.SHOP_API_BASE}${postData.url}`;
		postData.config.headers['X-Q-Dance-Event-Namespace'] = payload;

		return this.$axios.get(postData.url, postData.config);
	},

	getShopproducts(context, { namespace, cartToken }) {
		const postData = {
			url: '/products',
			method: 'GET',
			config: {
				headers: {}
			}
		};

		postData.url = `${process.env.SHOP_API_BASE}${postData.url}`;
		postData.config.headers['X-Q-Dance-Event-Namespace'] = namespace;
		if (cartToken) {
			// console.log('Getting Products with:',cartToken)
			postData.config.headers['x-cart-token'] = `${cartToken}`;
		} else {
			// console.log('Getting Products without carttoken')
		}

		return this.$axios.get(postData.url, postData.config);
	},

	async getOrder(context, { namespace, reference, cartToken }) {
		const postData = {
			url: '/get-orders',
			method: 'POST',
			config: {
				headers: {}
			},
			params: {}
		};

		if (reference['consumer_email']) {
			postData.params['consumer_email'] = `${reference['consumer_email']}`;
			// console.log('/get-orders with consumer_email:',reference['consumer_email'])
		} else if (reference['reference']) {
			postData.params['reference'] = `${reference['reference']}`;
			// console.log('/get-orders with reference (PL):',reference['reference'])
		} else if (reference['consumer_external_reference']) {
			postData.params['consumer_external_reference'] = `${reference['consumer_external_reference']}`;
			// console.log('/get-orders with consumer_external_reference (BID):',reference['consumer_external_reference'])
		} else {
			postData.params['reference'] = false;
		}
		postData.url = `${process.env.SHOP_API_BASE}${postData.url}`;
		postData.config.headers['x-event-namespace'] = namespace;
		if (cartToken) {
			postData.config.headers['x-cart-token'] = cartToken;
			// console.log('/get-orders with cartToken:',cartToken)
		}

		let query = new Promise((res, rej) => {
			this.$axios
				.post(postData.url, postData.params, postData.config)
				.then(response => {
					let rawResponseObj = {
						...response
					};
					if (rawResponseObj.data.data.upsell.hospitality['add_on'].length > 0) {
						rawResponseObj.data.data.upsell.hospitality['add_on'].forEach(addon => {
							addon.cart.package.slug = addon.cart.package.name
								.replace(/[^a-zA-Z0-9 ]/g, '')
								.replace(/\s+/g, '-')
								.toLowerCase();
							return addon;
						});
					}

					res(rawResponseObj);
				})
				.catch(err => {
					console.log(err);
					res(false);
				});
		});

		return await query;
	},

	createShopcart(context, payload) {
		const postData = {
			url: '/carts',
			method: 'POST',
			config: {
				headers: {}
			}
		};

		postData.url = `${process.env.SHOP_API_BASE}${postData.url}`;
		postData.config.headers['X-Q-Dance-Event-Namespace'] = payload.namespace;

		/* if (
			context.rootGetters['shopconfig/getData'].membersale ||
			context.rootGetters['shopconfig/getData'].travelsale
		) {
			postData.config.headers['X-User-Token'] = controller.getAccessTokens().idToken;
		} */

		// Don't add user token to request for Thunderdome because of an error in the backend
		//context.rootGetters['sso/getSsoBrand'] !== 'thunderdome'
		// if (controller && context.rootGetters['sso/getIsUserLoggedIn']) {
		// 	postData.config.headers['X-User-Token'] = controller.getAccessTokens().idToken;
		// }

		if (context.getters.getUpgradeOrderId) {
			postData.config.headers['X-Upgradable-Order-Id'] = context.getters.getUpgradeOrderId;
		}

		if (context.getters.getAccessId) {
			postData.config.headers['X-Upgradable-Order-Id'] = context.getters.getAccessId;
		}

		if (payload.policyId) {
			postData.config.headers['X-Policy-Id'] = payload.policyId;
		}

		if (payload.paymentQueueEnabled) {
			// console.log('ENABLED');
			postData.params = {
				paymentQueueToken: payload.paymentQueueToken
			};

			if (Array.isArray(postData.params.paymentQueueToken)) {
				console.warn('Payment Queue Token should be a string not an array.');
			}
		}

		return this.$axios.post(postData.url, postData.params, postData.config);
	}
};

export const mutations = {
	setHasConfig(state, payload) {
		state.hasConfig = payload;
	},

	setOssConfig(state, payload) {
		state.ossConfig = payload;
	},

	setIsTouch(state, payload) {
		state.isTouch = payload;
	},

	setShowCheckout(state, payload) {
		if (payload) {
			document.querySelector('body').classList.add('checkout-open');
		} else {
			document.querySelector('body').classList.remove('checkout-open');
		}

		state.showCheckout = payload;
	},

	setShowCheckoutForm(state, payload) {
		state.showCheckoutForm = payload;
	},

	setReadytoShop(state, payload) {
		state.readyToshop = payload;
	},

	setShowCounter(state, payload) {
		state.showCounter = payload;
	},

	setModalActive(state, payload) {
		state.modalActive = payload;
	},

	setModalPrefillCartActive(state, payload) {
		state.modalPrefillCartActive = payload;
	},

	setModalMessage(state, payload) {
		state.modalMessage = payload;
	},

	setModalTitle(state, payload) {
		state.modalTitle = payload;
	},

	setBrand(state, payload) {
		state.brand = payload;
	},

	setModalReplacementActive(state, payload) {
		state.modalReplacementActive = payload;
	},

	setReplacementName(state, payload) {
		state.replacementName = payload;
	},

	setReplacementAmount(state, payload) {
		state.replacementAmount = payload;
	},

	setMainBackgroundImage(state, payload) {
		state.mainBackgroundImage = payload;
	},

	setStaticTranslationData(state, payload) {
		state.statictranslationJson = payload;
	},

	setTranslationData(state, payload) {
		state.translationJson = payload;
	},

	setBrandLogo(state, payload) {
		state.brandLogo = payload;
	},

	setShopRoutingFlow(state, payload) {
		state.shopRoutingFlow = payload;
	},
	setCartId(state, payload) {
		// console.log('cart;', payload);
		state.cartId = payload;
	},
	setUpgradeOrderId(state, payload) {
		state.upgradeOrderId = payload;
	},
	setAccessId(state, payload) {
		state.accessId = payload;
	},
	setIsWishlistSaved(state, payload) {
		state.isWishlistSaved = payload;
	},
	setIsUpgradeChoiceMade(state, payload) {
		state.isUpgradeChoiceMade = payload;
	},

	setIsTermsAccepted(state, payload) {
		state.isTermsAccepted = payload;
	},
	setIsCovidTermsAccepted(state, payload) {
		state.isCovidTermsAccepted = payload;
	},

	setPrebuilderChoice(state, payload) {
		state.prebuilderChoice = payload;
	},

	setErrorCodes(state, payload) {
		state.errorCodes = payload;
	},
	setDeepLinkQueryString(state, payload) {
		state.deepLinkQueryString = payload;
	},
	setFromTravelDetailPath(state, payload) {
		state.fromTravelDetailPath = payload;
	},
	setLookUpState(state, payload) {
		state.lookUpState = payload;
	},
	setCompareTransportCat(state, payload) {
		state.compareTransportCat = payload;
	},

	setLocale(state, payload) {
		const locale = new LocaleLanguage(payload);

		if (locale.language !== 'unkown') {
			state.locale = locale;
		}
	},

	setCustomTranslations(state, payload) {
		if (payload) {
			const safe_translations = JSON.stringify(payload);
			state.customTranslations = safe_translations;
		}
	},

	setCountryCodes(state, payload) {
		state.countryCodes = payload;
	},

	setCountryPhoneCodes(state, payload) {
		state.countryPhoneCodes = payload;
	}
};

export const getters = {
	getHasConfig: state => state.hasConfig,
	getOssConfig: state => state.ossConfig,
	getShowCheckout: state => state.showCheckout,
	getShowCheckoutForm: state => state.showCheckoutForm,
	getReadytoShop: state => state.readyToshop,
	getShowCounter: state => state.showCounter,
	getModalActive: state => state.modalActive,
	getIsTouch: state => state.isTouch,
	getModalPrefillCartActive: state => state.modalPrefillCartActive,
	getModalTitle: state => state.modalTitle,
	getModalMessage: state => state.modalMessage,
	getBrand: state => state.brand,
	getMainBackgroundImage: state => state.mainBackgroundImage,
	getTranslationData: state => state.translationJson,
	getStaticTranslationData: state => state.statictranslationJson,
	getBrandLogo: state => state.brandLogo,
	getShopRoutingFlow: (state, getters, rootStore, rootGetters) => {
		// const { upgradableOrder } = rootGetters['shopconfig/getData'];
		// console.log(upgradableOrder, rootGetters['shopconfig/getData']);
		if (
			rootGetters['shopconfig/getData'] &&
			rootGetters['shopconfig/getData'].hasOwnProperty('upgradableOrder') &&
			rootGetters['shopconfig/getData'].upgradableOrder
		) {
			const { upgradableOrder } = rootGetters['shopconfig/getData'];
			const skipSteps = [];
			upgradableOrder.skipTravel && skipSteps.push('slug-travel');
			upgradableOrder.skipBus && skipSteps.push('slug-transportation');
			return state.shopRoutingFlow.filter(route => !skipSteps.includes(route.name));
		}
		return state.shopRoutingFlow;
	},
	getCartId: state => state.cartId,
	getUpgradeOrderId: state => state.upgradeOrderId,
	getAccessId: state => state.accessId,
	getIsWishlistSaved: state => state.isWishlistSaved,
	getIsUpgradeChoiceMade: state => state.isUpgradeChoiceMade,
	getIsTermsAccepted: state => state.isTermsAccepted,
	getIsCovidTermsAccepted: state => state.isCovidTermsAccepted,
	getPrebuilderChoice: state => state.prebuilderChoice,
	getErrorcodes: state => state.errorCodes,
	getDeepLinkQueryString: state => state.deepLinkQueryString,
	getFromTravelDetailPath: state => state.fromTravelDetailPath,
	getLookUpState: state => state.lookUpState,
	getModalReplacementActive: state => state.modalReplacementActive,
	getReplacementName: state => state.replacementName,
	getReplacementAmount: state => state.replacementAmount,
	getCompareTransportCat: state => state.compareTransportCat,
	getLocale: state => state.locale,
	getCustomTranslations: state =>
		state.customTranslations && Object.keys(state.customTranslations).length
			? JSON.parse(state.customTranslations)
			: {},
	getCountryCodes: state => state.countryCodes,
	getCountryPhoneCodes: state => state.countryPhoneCodes,
	getHideLogin: state => state.ossConfig?.hideLogin
};
