<template>
	<div class="basket-confirm" v-if="allowedToRender && hasProductsInBasket">
		<div v-if="!isCheckoutAllowed || customError" class="basket-confirm__errors">
			<p>{{ $t('alert.no_checkout') }}</p>

			<ul>
				<li v-for="(error, index) in checkOutErrors" :key="`error${index}`">
					<span v-html="error.message"></span>
				</li>
			</ul>
		</div>

		<Button
			class="button--alternative-second"
			v-if="!shopConfig.preregistration"
			@click.native="gotoPersonalDetail"
			:is-disabled="!isCheckoutAllowed"
		>
			<span>{{ $t('label.cart.order') }}</span>
		</Button>

		<Button class="button--alternative-second" v-else>
			<span>{{ $t('label.cart.save') }}</span>
		</Button>
	</div>
</template>

<script>
import isShopifyProduct from '@/utils/isShopifyProduct';
import isLightSpeedProduct from '@/utils/isLightSpeedProduct';

export default {
	name: 'BasketConfirm',

	data() {
		return {
			customError: false,
			checkOutErrors: null,
			allowedToRender: true,
			// productAlreadyPurchased: false,
			productName: ''
		};
	},

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		hasMerchandise() {
			return !!this.productsInBasket.merchandise;
		},

		hasProductsInBasket() {
			return Object.keys(this.productsInBasket).length > 0;
		},

		hasShopifyMerchandise() {
			if (this.hasMerchandise) {
				return this.productsInBasket.merchandise.some(product => isShopifyProduct(product.type.id));
			}

			return false;
		},

		hasLightSpeedMerchandise() {
			if (this.hasMerchandise) {
				return this.productsInBasket.merchandise.some(product => isLightSpeedProduct(product.type.id));
			}

			return false;
		},

		isInsurableProductInBasket() {
			return this.$store.getters['shopapi/getIsInsurableProductInCart'];
		},

		isRefundProtectionAvailable() {
			const isInsuranceAvailable =
				this.$store.getters['shopapi/getProducts'].insurance.length && !this.shopConfig.disableRefund;

			return isInsuranceAvailable && this.isInsurableProductInBasket;
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		isCheckoutAllowed() {
			return this.shopCart && this.shopCart.checkoutAllowed;
		},

		hasShippingCountry() {
			return !!this.$store.getters['shopapi/getShippingCountry'];
		},

		hasShippingHandle() {
			return !!this.$store.getters['shopapi/getShippingHandle'];
		},

		isRefundChoiceMade() {
			return this.$store.getters['shopapi/getIsRefundChoiceMade'];
		},

		isTermsAccepted() {
			return this.$store.getters.getIsTermsAccepted;
		},

		isCovidTermsAccepted() {
			return this.$store.getters.getIsCovidTermsAccepted;
		},

		hasTravelButNoTickets() {
			const hasTravel = this.productsInBasket.hasOwnProperty('travel');
			const hasTicket = this.productsInBasket.hasOwnProperty('ticket');

			if (hasTravel) {
				return !hasTicket ? true : false;
			}

			return false;
		},

		hasUpgradeModule() {
			return this.$store.getters['shopconfig/getHasUpgradeModule'];
		},

		isUpgradeBookingProtectAvailable() {
			return (
				this.shopConfig.upgradableOrder &&
				this.shopConfig.upgradableOrder.bookingProtect &&
				this.shopConfig.upgradableOrder.bookingProtect.currentOrder
			);
		},

		isAddonShop() {
			return this.shopConfig.shopInit && this.shopConfig.shopInit.isAddOnShop;
		}
	},

	methods: {
		gotoPersonalDetail() {
			if (this.hasTravelButNoTickets) {
				if (!this.isAddonShop) {
					this.$fireErrorEventWithPromis(6003).then(response => {
						this.checkAllTerms() &&
							this.isCheckoutAllowed &&
							this.$store.commit('setShowCheckoutForm', true);
					});
					return;
				}
			}

			if (this.checkAllTerms() && this.isCheckoutAllowed) {
				this.$store.commit('setShowCheckoutForm', true);
			}
		},

		checkAllTerms() {
			let allOk = true;
			this.customError = false;
			this.checkOutErrors = [...this.shopCart.checkoutErrors];

			if (!this.isTermsAccepted) {
				this.checkOutErrors.push({
					code: 'custom.error',
					message: this.$t('error.form_terms_not_accepted')
				});
				this.customError = true;
				allOk = false;
			}

			if (this.shopConfig.showCovidTerms !== false && !this.isCovidTermsAccepted) {
				this.checkOutErrors.push({
					code: 'custom.error',
					message: this.$t('error.form_covid_not_accepted')
				});
				this.customError = true;
				allOk = false;
			}

			if (this.productsInBasket.merchandise) {
				if (!this.hasShippingCountry) {
					this.checkOutErrors.push({
						code: 'custom.error',
						message: this.$t('error.form_no_country_selected')
					});
					this.customError = true;
					allOk = false;
				} else if (this.hasShopifyMerchandise && !this.hasShippingHandle) {
					this.checkOutErrors.push({
						code: 'custom.error',
						message: this.$t('error.form_no_shipping_costs_selected')
					});
					this.customError = true;
					allOk = false;
				}
			}

			if (this.isRefundProtectionAvailable && !this.isRefundChoiceMade) {
				if (!this.hasUpgradeModule || (this.hasUpgradeModule && this.isUpgradeBookingProtectAvailable)) {
					this.checkOutErrors.push({
						code: 'custom.error',
						message: this.$t('error.form_no_insurance_choice')
					});
					this.customError = true;
					allOk = false;
				}
			}

			return allOk;
		}
	},

	mounted() {
		this.checkOutErrors = !!this.shopCart.checkoutErrors ? [...this.shopCart.checkoutErrors] : [];
		// this.checkIfProductBoughtEarlier();
	}
};
</script>

<style lang="scss">
$component: 'basket-confirm';

.#{$component} {
	display: block;

	// padding: $global-spacing;
	// background-color: rgba(black, 0.5);

	/* &__terms {
		font-size: 14px;
		line-height: 1.4;

		input + label {
			font-size: inherit;
			text-transform: none;
		}

		p {
			margin-bottom: 0.5em;
		}
	} */

	&__errors {
		padding: $global-spacing;
		margin-bottom: calc($global-spacing / 2);

		color: $color-dark-blue;
		line-height: 1.2;
		font-size: 16px;

		background-color: $color-white;

		p {
			margin-bottom: 6px;

			color: $color-red;
			font-family: $font-bold;
		}

		ul {
			list-style-type: disc;
			padding-left: 1.2em;
		}
	}

	&__cta {
		padding-top: 20px;
	}

	@include sm {
		&__ammount {
			display: flex;
			justify-content: space-between;

			text-transform: capitalize;
			font-size: 18px;

			padding: 0 0 0 $global-spacing--mobile;

			background: none;
		}

		/* &__terms {
			font-size: 14px;

			label {
				font-size: inherit;
			}
		} */
	}
}
</style>
