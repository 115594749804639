<template>
	<div class="radiobutton-async" :class="{ 'radiobutton-async--disabled': isDisabled }">
		<input
			class="radiobutton-async__option"
			:class="{ 'radiobutton-async__option--checked': isChecked }"
			type="radio"
			:id="data.value"
			:disabled="isDisabled"
			@click.prevent="onInput"
		/>

		<label class="radiobutton-async__option-label" :for="data.value" v-html="data.label" @click.prevent="onInput" />
	</div>
</template>

<script>
export default {
	name: 'RadioButtonAsync',

	props: {
		data: Object,
		isDisabled: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		isChecked() {
			return this.data.selectedValue === this.data.value;
		}
	},

	methods: {
		onInput(e) {
			!this.isDisabled && !this.isChecked && this.$emit('input', this.data.value);
		}
	}
};
</script>

<style lang="scss">
$component: 'radiobutton-async';

.#{$component} {
	display: flex;
	width: 100%;
	height: 24px;

	margin-bottom: 16px;

	overflow: hidden;

	&--disabled {
		opacity: 0.5;
	}

	&__option {
		position: absolute;

		visibility: hidden;
		opacity: 0;
		width: 0;
		height: 0;

		&:checked,
		&--checked {
			visibility: visible;
			opacity: 1;
		}

		&:checked + label,
		&--checked + label {
			color: $color-white;

			&:before {
				display: block;
				background-color: rgba(var(--primary-color), 1);
				box-shadow: inset 0px 0px 0px 6px $color-black;
			}
		}
	}

	&__option-label {
		cursor: pointer;
		position: relative;

		display: flex;
		flex-wrap: nowrap;

		justify-content: flex-start;
		align-items: center;

		width: 100%;
		min-width: 65px;
		height: 100%;

		font-size: 16px;

		color: $color-white;
		text-transform: capitalize;

		&:before {
			content: '';

			position: relative;
			display: block;

			width: 24px;
			height: 100%;
			left: 0;

			margin-right: 11px;

			background-color: rgba(var(--primary-color), 0);
			border: 1px solid #ffffff;
			border-radius: 50%;

			transition: all 0.5s $ease-in-out-cubic;
		}
	}
}
</style>
