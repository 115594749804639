<template>
	<div class="basket-item">
		<div class="basket-item__inner">
			<div class="basket-item__details">
				<div class="basket-item__visual md-lg-only">
					<img v-if="data.image" :src="cdnUrl(data.image)" />

					<div v-else-if="data.icon || data.iconSrc" class="basket-item__visual-icon">
						<img :src="data.iconSrc" v-if="data.iconSrc" />

						<component :is="data.icon" v-else />
					</div>
				</div>

				<div class="basket-item__content">
					<h3 class="basket-item__title" v-html="data.title"></h3>

					<p class="basket-item__description" v-html="data.description"></p>

					<div v-if="data.terms" v-html="data.terms" class="basket-item__terms md-lg-only"></div>

					<InputNumberField
						v-if="!data.hideButtons"
						class="basket-item__input md-lg-only"
						v-model="numberOfProducts"
						:isDisabled="isShopUpdatePending"
						:stock="data.stockAvailable || data.stock"
						:max-value="ticketFilterInputStepper"
						:min-value="hasMiniminAmount"
						:step-size="data.stepSize"
						:type="data.type === 'ticket' ? 'ticket-stepper' : null"
					/>

					<Button
						v-if="data.showRemoveButton"
						class="basket-item__button-remove button--small md-lg-only"
						@click.native="$emit('remove', data.id)"
						:isDisabled="isShopUpdatePending"
					>
						{{ $t('page.shop.experience_builder.travel.remove') }}
					</Button>
				</div>
			</div>

			<div
				class="basket-item__price-container"
				:class="{ 'basket-item__price-container--has-buttons': !data.hideButtons }"
			>
				<h3 class="basket-item__price">{{ (data.price * numberOfProducts) | formatPrice }}</h3>

				<InputNumberField
					v-if="!data.hideButtons"
					class="basket-item__input sm-only"
					v-model="numberOfProducts"
					:isDisabled="isShopUpdatePending"
					:stock="data.stockAvailable || data.stock"
					:max-value="ticketFilterInputStepper"
					:min-value="hasMiniminAmount"
					:step-size="data.stepSize"
					:type="data.type === 'ticket' ? 'ticket-stepper' : null"
				/>

				<Button
					v-if="data.showRemoveButton"
					class="basket-item__button-remove button--small sm-only"
					@click.native="$emit('remove', data.id)"
					:isDisabled="isShopUpdatePending"
				>
					{{ $t('page.shop.experience_builder.travel.remove') }}
				</Button>
			</div>
		</div>

		<div v-if="data.terms" v-html="data.terms" class="basket-item__terms sm-only"></div>
	</div>
</template>

<script>
import InputNumberField from '@/components/ui/InputNumberField';

export default {
	name: 'BasketItem',

	components: {
		InputNumberField
	},

	props: {
		data: {
			type: Object,
			default: function () {
				return {
					id: '',
					title: 'Basket item',
					description: '',
					price: 0,
					numberOfProducts: 0,
					stock: 0,
					maxAmount: 0,
					touristTax: 0,
					showEntertainmentFee: false,
					entertainmentFee: 0,
					image: null,
					icon: '',
					iconSrc: null,
					hideButtons: false,
					showRemoveButton: false,
					stepSize: 1,
					type: '',
					data: {}
				};
			}
		}
	},

	computed: {
		allowedExtraTickets() {
			return this.shopConfig.shopSettings.fields.allowedExtraTickets;
		},

		productsInCart() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		hasMiniminAmount() {
			if (
				this.data.data.hasOwnProperty('type') &&
				this.data.data.type.hasOwnProperty('meta') &&
				this.data.data.type.meta.hasOwnProperty('minimumQuantity')
			) {
				return this.data.data.type.meta.minimumQuantity;
			}
			return 0;
		},

		isShopUpdatePending() {
			return this.$store.getters['shopapi/getIsShopUpdatePending'];
		},

		ticketFilterInputStepper() {
			if (this.data) {
				switch (this.data.type) {
					case 'ticket':
						return this.calculateMaxtickets(this.data) >= this.data.stock
							? this.data.stock
							: this.calculateMaxtickets(this.data);
					case 'merchandise':
					case 'extra':
					case 'comparetransport':
					case 'transport':
					default:
						return Math.min(this.data.stock, this.data.maxAmount);
				}
			}
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		numberOfProducts: {
			get() {
				return this.data.numberOfProducts || 1;
			},

			set(val) {
				const emitData = {
					productId: this.data.id,
					amount: val
				};

				this.data.reference && (emitData['reference'] = this.data.reference);
				this.$emit('input', emitData);

				const tempData = {
					name: this.data.title,
					price: this.data.type == 'ticket' ? this.data.data.type.price : this.data.price,
					productId: this.data.id,
					amount: val - this.numberOfProducts,
					shop: this.$store.getters['shopconfig/getData'].titleFull,
					type: this.data.type
				};
				const hasVariant = this.data.type == 'merchandise' && this.data.description;
				hasVariant && (tempData.variant = hasVariant);

				// if (val - this.numberOfProducts == 1) {
				// 	this.addToDataLayer({
				// 		products: [tempData]
				// 	});
				// } else {
				// 	this.removeFromDataLayer({
				// 		products: [tempData]
				// 	});
				// }
			}
		}
	},

	methods: {
		calculateMaxtickets(ticketItem) {
			let maxTickets = 0;
			let totalMaxAmount = this.shopConfig.maxTicketAmount;
			let totalAmount = 0;
			let amount = 0;
			let { ticket: ticketsInCart } = this.productsInCart;

			if (this.allowedExtraTickets && ticketsInCart) {
				if (this.allowedExtraTickets[0].includes(ticketItem.id)) {
					totalMaxAmount = this.allowedExtraTickets[1].maxQuantity;
					// Only check the special tickets as they follow a different rule
					ticketsInCart = ticketsInCart.filter(ticket =>
						this.allowedExtraTickets[0].includes(ticket.type.id)
					);
				} else {
					//remove the special tickets from the regular tickets
					ticketsInCart = ticketsInCart.filter(
						ticket => !this.allowedExtraTickets[0].includes(ticket.type.id)
					);
				}
			}

			if (ticketsInCart) {
				ticketsInCart.forEach(ticket => {
					if (ticket.type.id === ticketItem.id) {
						amount = ticket.numproducts;
					}
					totalAmount += ticket.numproducts;
				});
			}

			if (amount >= ticketItem.maxAmount) return 0;
			if (totalAmount >= totalMaxAmount) return 0;

			maxTickets = totalMaxAmount - totalAmount;

			return maxTickets <= 0 ? 0 : maxTickets;
		}
	}
};
</script>

<style lang="scss">
$component: 'basket-item';

.#{$component} {
	margin-bottom: $global-spacing;

	&__inner {
		display: flex;
		justify-content: space-between;
	}

	&__details {
		display: flex;
		min-width: 0;
	}

	&__visual {
		flex-shrink: 0;
		width: 120px;
		height: 120px;

		margin-right: $global-spacing;

		border: 1px solid var(--primary-body-color);

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
		}
	}

	&__visual-icon {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 100%;
		height: 100%;

		svg {
			width: 50px;
			height: 50px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		min-width: 0;
	}

	&__title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		max-width: 100%;
	}

	&__description {
		margin-bottom: 0.5em;
	}

	.input-number-field {
		width: 120px;

		&__input-wrapper {
			height: 30px;
		}
		&__input {
			width: calc(100% - 62px);
		}
		&__icon {
			width: 30px;
		}
	}

	&__price-container--has-buttons {
		min-width: 100px;
	}

	&__price {
		padding-left: 10px;
		text-align: right;

		&--tax {
			font-size: 14px;
		}
	}

	&__terms {
		font-size: 12px;
		line-height: 1.4;
		font-style: italic;
	}

	&__button-remove.button {
		width: auto;
		padding-left: 15px;
		padding-right: 15px;
	}

	@include lg {
		&__terms {
			width: 80%;
		}
	}

	@include md-lg {
		&__description {
			flex: auto;
		}
	}

	@include sm {
		&__title {
			flex: auto;
		}

		&__price-container {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: flex-end;

			&--has-buttons {
				min-width: 130px;
			}
		}

		&__price {
			flex: auto;
			margin-bottom: 4px;
		}
	}
}
</style>
